// Node Modules
import { useStopwatch } from "react-timer-hook";
import React, {
  createContext,
  useContext,
  useState,
} from "react";

// Scripts
import { callStatuses } from "@/scripts/constant-types/voice/twilioConstants";

// Types
import ICallContextProvider from "@/interfaces/context/voice/ICallContextProvider";
import ICallInformationContext from "@/interfaces/context/voice/ICallInformationContext";
import ICallState from "@/interfaces/twilio/voice/ICallState";

const callInitialState: ICallState = {
  buttonLabel: "Call Us Now",
  callStatus: callStatuses.inactive,
  device: undefined,
};

const CallContext = createContext<ICallInformationContext | undefined>(undefined);

const useCallContext = () => {
  const context = useContext(CallContext);
  if (context === undefined) {
    throw new Error("useCallContext must be used within a CallContextProvider");
  }
  return context;
};

const CallContextProvider = ({
  children,
  callStateValue,
}: ICallContextProvider) => {
  const [callState, setCallState] = useState<ICallState>(callStateValue ?? callInitialState);

  const stopWatch = useStopwatch({
    autoStart: false,
  });

  const contextValues: ICallInformationContext = {
    callState,
    setCallState,
    stopWatch,
  };

  return (
    <CallContext.Provider
      value={contextValues}
    >
      {children}
    </CallContext.Provider>
  );
};

export {
  CallContext,
  CallContextProvider,
  useCallContext
};
