// Helpers
import {
  getNextDeviceInformationUpdateDate,
  isDeviceInformationNotSet,
  isTimeToRefreshDeviceInformation,
  loadHardwareComponents
} from "@/scripts/deviceInformationHelper";

// Node Modules
import {
  useEffect,
  useState
} from "react";

// Services
import { getDeviceInformationFromKfcSystem } from "@/components/services/deviceInformationService";
import { logOnDataDog } from "@/components/services/dataDogLoggingService";
import {
  getDeviceInfoFromLocalStorage,
  saveDeviceInfoOnLocalStorage
} from "@/components/services/localStorageService";

// Types
import IDeviceInformation from "@/interfaces/device/IDeviceInformation";
import { IUseDeviceInformationHandlerReturn } from "@/interfaces/hooks/device-information/IUseDeviceInformationHandlerReturn";
import { StatusType } from "@datadog/browser-logs";

export const useDeviceInformationHandler = (
  kioskComputerNames: string[]
): IUseDeviceInformationHandlerReturn => {
  const [areHardwareComponentsLoaded, setAreHardwareComponentsLoaded] = useState(false);
  const [deviceInformation, setDeviceInformation] = useState<IDeviceInformation>(getDeviceInfoFromLocalStorage());
  
  const getDeviceInformation = async () => {
    try {
      const kioskDeviceInformation = await getDeviceInformationFromKfcSystem();

      const newDeviceInformation = {
        ...kioskDeviceInformation,
        lastUpdatedTime: new Date(),
        nextUpdateDate: getNextDeviceInformationUpdateDate(),
      };

      setDeviceInformation(newDeviceInformation);
      saveDeviceInfoOnLocalStorage(newDeviceInformation);
    } catch (error:any) {
      logOnDataDog(error, StatusType.error);
    }
  }

  const shouldWeGetDeviceInformation = ():boolean => {
    if (areHardwareComponentsLoaded === true) {
      return isDeviceInformationNotSet(deviceInformation) ||
      isTimeToRefreshDeviceInformation(deviceInformation, kioskComputerNames);
    } else {
      return false;
    }
  }

  const startHardwareComponentsLoading = async () => {
    const resultForHardwareComponentsLoading = await loadHardwareComponents(deviceInformation);
    setAreHardwareComponentsLoaded(resultForHardwareComponentsLoading);
  }
  
  useEffect(() => {
    if (shouldWeGetDeviceInformation()) {
      getDeviceInformation();
    }
  }, [areHardwareComponentsLoaded]);

  useEffect(() => {
    startHardwareComponentsLoading();
  }, []);
  
  return ({
    deviceInformation,
  });
}
