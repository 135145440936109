// Node Modules
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

// Types
import ILoaderContext from "@/interfaces/context/loader/ILoaderContext";
import ILoaderContextProvider from "@/interfaces/context/loader/ILoaderContextProvider";

const LoaderContext = createContext<ILoaderContext | undefined>(undefined);

const useLoaderContext = (): ILoaderContext => {
  const context = useContext(LoaderContext);
  if (context === undefined) {
    throw new Error("useLoaderContext must be used within a LoaderContextProvider");
  }

  return context;
};

const LoaderContextProvider = ({
  isLoadingInitialValue = false,
  children,
}: ILoaderContextProvider) => {
  const [isLoading, setIsLoading] = useState<boolean>(isLoadingInitialValue);
  const showLoader = (): void => setIsLoading(true);
  const hideLoader = (): void => setIsLoading(false);

  /**
   * This function is a wrapper that shows the loader, executes an action or method
   * passed as a parameter and then hides the loader when the action is completed.
   * @param action The action/method to be executed.
   */
  const triggerLoaderForAction = async (action: () => Promise<void>): Promise<void> => {
    showLoader();
    await action();
    hideLoader();
  };

  /**
   * This function is a wrapper that shows the loader, executes callback function
   * passed as a parameter and then hides the loader when the execution is completed.
   * @param callBack The function to be executed.
   */
  const triggerLoaderForFunction = async (callBack: () => Promise<any>): Promise<any> => {
    showLoader();

    const result = await callBack();
    hideLoader();

    return result;
  };

  const value: ILoaderContext = useMemo(() => ({
    hideLoader,
    isLoading,
    showLoader,
    triggerLoaderForAction,
    triggerLoaderForFunction,
  }), [isLoading]);

  return (
    <LoaderContext.Provider
      value={value}
    >
      {children}
    </LoaderContext.Provider>
  );
};

export {
  LoaderContext,
  LoaderContextProvider,
  useLoaderContext,
};
