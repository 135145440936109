// Node Modules
import Pusher, { Options } from "pusher-js";

/**
 * This function returns a pusher instance. There MUST be only one instance per client.
 * Otherwise we will end up with more concurrent instances than we need, and they are charged.
 * @returns A Pusher instance that can be used to listen to events sent from other applications.
 */
const getPusherInstance = () => {
  const options: Options = {
    cluster: process.env.PUSHER_CHANNELS_CLUSTER,
  };

  return new Pusher(process.env.PUSHER_CHANNELS_KEY, options);
};

export default {
  getPusherInstance,
}
