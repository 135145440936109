// Context
import { useDataDogContext } from "@/context/DataDogContext";
import { useVideoCallContext } from "@/context/VideoCallContext";

// Interfaces
import IAttractionLoopProps from "@/interfaces/attraction-loop/IAttractionLoopProps";

// Node Modules
import {
  KeyboardEvent,
  useEffect,
  useRef
} from "react";

// Styles
import { getAttractionLoopStyles } from "./StylesAttractionLoop";

const AttractionLoop = ({
  closeAttractionLoop,
  videoSrc,
}: IAttractionLoopProps) => {
  const {
    className,
    styles,
  } = getAttractionLoopStyles();

  const {
    stopDataDogRealUserMonitoringSession,
  } = useDataDogContext();

  const {
    isIncomingCall,
  } = useVideoCallContext();

  const divElement = useRef(null);

  useEffect(() => {
    divElement.current.focus();

    stopDataDogRealUserMonitoringSession();
  }, []);

  useEffect(() => {
    if (isIncomingCall) {
      closeAttractionLoop();
    }
  }, [isIncomingCall]);

  return (
    <>
      <div
        className={`video-container ${className}`}
        data-testid="video-container"
        ref={divElement}
        role="video-container"
        tabIndex={0}
        onClick={closeAttractionLoop}
        onDrag={closeAttractionLoop}
        onKeyDown={async (event: KeyboardEvent<HTMLDivElement>) => await closeAttractionLoop(event)}
      >
        <video
          autoPlay
          loop
          muted
          className={`${className} video`}
          controls={false}
          data-testid="attraction-video"
          id="attraction-loop-video"
        >
          <source
            data-testid="attraction-video-src"
            role="attraction-loop-video-src"
            src={`https:${videoSrc}`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      {styles}
    </>
  );
};

export default AttractionLoop;
