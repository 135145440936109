// Types
import IHoursOfOperationDetails from "@/interfaces/splash-screen/IHoursOfOperationDetails";

const getCurrentDay = () => {
  const day = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  return day[new Date().getDay()];
}

const getOpeningHours = (hoursOfOperation: IHoursOfOperationDetails) => {
  const kioskDay = getCurrentDay();
  
  if (hoursOfOperation[kioskDay] == "Closed") {
    return hoursOfOperation[kioskDay];
  }

  const opening: string = hoursOfOperation[kioskDay]?.split("-")[0];
  return opening
    ? opening.trim()
    : undefined;
}

const getClosingHours = (hoursOfOperation: IHoursOfOperationDetails) => {
  const kioskDay = getCurrentDay();
  
  if (hoursOfOperation[kioskDay] == "Closed") {
    return hoursOfOperation[kioskDay];
  }

  const closing: string = hoursOfOperation[kioskDay]?.split("-")[1];
  return closing
    ? closing.trim()
    : undefined;
}

const isAValidSchedule = (hoursOfOperation: IHoursOfOperationDetails) => {
  if (!hoursOfOperation || Object.keys(hoursOfOperation).length === 0) {
    return false;
  }

  const keysAsDays = [
    "friday",
    "monday",
    "saturday",
    "sunday",
    "thursday",
    "tuesday",
    "wednesday",
  ];
 
  for (const day of keysAsDays) {
    if (!hoursOfOperation.hasOwnProperty(day)) {
      return false;
    }
  }
    
  return true;
}
const isScheduleInCloseHours = (hoursOfOperation: IHoursOfOperationDetails) => {
  const isClose = true;
  const isOpen = false
  if (!hoursOfOperation || !isAValidSchedule(hoursOfOperation)) {
    return false;
  }

  let now = new Date();
  const isTimeZoneAvailable = hoursOfOperation.timeZone && hoursOfOperation.timeZone.length;

  if (isTimeZoneAvailable) {
    const dateWithTimeZone = new Date(new Date().toLocaleString("en-US", {
      timeZone: hoursOfOperation.timeZone,
    }));

    now = dateWithTimeZone;
  }
    
  const currentTimestamp = now.valueOf();
  const currentDay = getCurrentDay();

  const todaySchedule: string = hoursOfOperation[currentDay];

  if (!todaySchedule || todaySchedule.toLocaleLowerCase() == "closed") {
    return isClose;
  }
  
  const startTimestamp = getScheduleTimestamp(todaySchedule, "startHour");
  const endTimestamp = getScheduleTimestamp(todaySchedule, "endHour");

  return (startTimestamp <= currentTimestamp && currentTimestamp < endTimestamp)
    ? isOpen
    : isClose;
}

const getScheduleTimestamp = (daySchedule: string, type: "startHour" | "endHour") => {
  const today = new Date();
  const timeSchedule = daySchedule.split("-");
  const morningCount = (daySchedule.match(/AM/g) || []).length;
  const isEndHour = type === "endHour";
  const nextDay = isEndHour && morningCount === 2;

  const todayInString = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate() + (nextDay
    ? 1
    : 0)}`;
  
  const dayScheduleTime = timeSchedule[isEndHour
    ? 1
    : 0];

  const dayWithScheduleTime = new Date(`${todayInString} ${dayScheduleTime}`);
  return dayWithScheduleTime.valueOf();
}

const hoursOfOperationParsingUtils = {
  getOpeningHours,
  getClosingHours,
  isScheduleInCloseHours,
}

export { hoursOfOperationParsingUtils }
