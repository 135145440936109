// Types
import IDeviceInformation from "@/interfaces/device/IDeviceInformation";

// Utils
import { getNumberOfDaysBetweenTwoDates } from "@/scripts/dateHelper";

import HardwareComponent from "@/classes/hardware-integration-module/HardwareComponent";
import KcfSystem from "@/classes/hardware-integration-module/KcfSystem";
import hardwareIntegrationModuleService from "@/services/hardwareIntegrationModuleService";
import {
  StatusType,
  logOnDataDog
} from "@/components/services/dataDogLoggingService";

/**
   * Gets the date on which the device information should be updated again
   * @returns Date which is the date for the next update for the device information
   */
const getNextDeviceInformationUpdateDate = (): Date => {
  const currentDate = new Date();
  const year = currentDate.getUTCFullYear();
  const monthIndex = currentDate.getUTCMonth();

  return monthIndex === 11
    ? new Date(year + 1, 0, 1)
    : new Date(year, monthIndex + 1, 1);
};

/**
   * Checks if the device information is empty or not set properly
   * @param deviceInformation  It's the object that contains the device information.
   * @param kioskComputerNames A list of all the kiosk computer names that need a force update.
   * @returns Boolean indicating if the device information is set or not
   */
const isDeviceInformationNotSet = (deviceInformation: IDeviceInformation) => {
  return !deviceInformation ||
    !deviceInformation.kioskComputerName ||
    !deviceInformation.ozStoreId ||
    !deviceInformation.zipCode;
}

/**
   * Checks if the device information object is out dated base on its following properties: nextUpdateDate and lastUpdatedTime
   * @param deviceInformation It's the object that contains the device information.
   * @returns A boolean indicating whether the device information is out dated or not
   */
const isDeviceInformationOutdated = (deviceInformation: IDeviceInformation):boolean => {
  const currentDate: Date = new Date();
  if (!deviceInformation || !deviceInformation.nextUpdateDate || !deviceInformation.lastUpdatedTime) {
    return true;
  }

  const nextUpdateDate = new Date(deviceInformation.nextUpdateDate).toDateString();
  const lastUpdatedTime = new Date(deviceInformation.lastUpdatedTime);

  return (nextUpdateDate === currentDate.toDateString()) ||
    getNumberOfDaysBetweenTwoDates(lastUpdatedTime, currentDate) >= 31
}

/**
   * Checks if there needs to be a force update on the device information
   * @param kioskComputerName Current Kiosk computer name
   * @param kioskComputerNames A list of all the kiosk computer names that need a force update.
   * @returns A boolean indicating whether there needs to be a force update on the device information.
   */
const isDeviceInfoUpdateForcedOnKiosk = (kioskComputerName: string, kioskComputerNames: string[]):boolean => {
  return !!kioskComputerNames && (kioskComputerNames.includes(kioskComputerName) || kioskComputerNames?.includes("ALL"));
};

/**
   * This function evaluates if the deviceInformation object needs to be updated.
   * @param deviceInformation It's the object that contains the device information.
   * @param kioskComputerNames It's a list that contains the Computer Names of the kiosks that have to be forced
   * to process a device information refresh.
   * @returns A boolean indicating whether the kiosk needs to set/update its device information object.
   */
const isTimeToRefreshDeviceInformation = (deviceInformation: IDeviceInformation, kioskComputerNames?: string[]):boolean => {
  return isDeviceInformationOutdated(deviceInformation) || isDeviceInfoUpdateForcedOnKiosk(deviceInformation.kioskComputerName, kioskComputerNames);
};

/**
   * This function is in in charge of loading hardware components
   * @param deviceInformation It's the object that contains the device information.
   * @returns A boolean indicating whether the hardware loading components was successful
   */
const loadHardwareComponents = async (deviceInformation: IDeviceInformation):Promise<boolean> => {
  try {
    const components: Array<HardwareComponent> = [new KcfSystem()];
    await hardwareIntegrationModuleService.loadHardwareComponents(components, deviceInformation);
    return true;
  } catch (error: any) {
    logOnDataDog(error, StatusType.error);
    return false;
  }
};

export {
  getNextDeviceInformationUpdateDate,
  isDeviceInformationNotSet,
  isDeviceInformationOutdated,
  isTimeToRefreshDeviceInformation,
  loadHardwareComponents
};
