// Node Modules
import Pusher, { Channel } from "pusher-js";

// Types
import IDeviceInformation from "@/interfaces/device/IDeviceInformation";
import IPusherChannelSubscriptionProps from "@/interfaces/web-socket-notifications/pusher/IPusherChannelSubscriptionProps";

abstract class PusherChannelSubscription {
  protected abstract _channelName: string
  protected _channel: Channel;
  protected _deviceInformation: IDeviceInformation;
  protected _pusher: Pusher;

  constructor(props: IPusherChannelSubscriptionProps) {
    this._pusher = props.pusherInstance;
    this._deviceInformation = props.deviceInformation;
  }

  public getChannelName = (): string => this._channelName;
  public unSubscribe = (): void => this._pusher && this._pusher.unsubscribe(this._channelName);
  public abstract registerEvents(): void;
};

export default PusherChannelSubscription;
