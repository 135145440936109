// Code obtained from https://ai.google.dev/edge/mediapipe/solutions/vision/face_detector/web_js#video
// Demo https://codepen.io/mediapipe-preview/pen/OJByWQr

// Node Modules
import {
  Detection,
  FaceDetector,
  FilesetResolver
} from "@mediapipe/tasks-vision";

// Services
import environmentVariablesService from "@/components/services/environmentVariablesService";

// Types
import ICameraReading from "@/interfaces/customer-detection/camera/ICameraReading";

class BlazeFaceDetector {
  _canvasElement: HTMLCanvasElement;
  _context: CanvasRenderingContext2D;
  _detector: FaceDetector | undefined;
  _shouldSaveCanvasFrames: boolean;
  _videoElement: HTMLVideoElement;
  _lastVideoTime = -1;
  constructor(videoElement: HTMLVideoElement, canvasElement: HTMLCanvasElement, shouldSaveCanvasFrames: boolean = false) {
    this._canvasElement = canvasElement;
    this._videoElement = videoElement;
    this._context = canvasElement.getContext("2d") as CanvasRenderingContext2D;
    this._shouldSaveCanvasFrames = shouldSaveCanvasFrames;
  }

  public async disposeDetector(): Promise<void> {
    this._detector.close();
    this._detector = null;
  };

  public async setUpDetector(): Promise<void> {
    const vision = await FilesetResolver.forVisionTasks(
      "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm"
    );

    this._detector = await FaceDetector.createFromOptions(vision, {
      baseOptions: {
        modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_detector/blaze_face_short_range/float16/1/blaze_face_short_range.tflite`,
        delegate: "GPU",
      },
      runningMode: "VIDEO",
    });
  };

  public async runDetector(): Promise<ICameraReading> {
    if (!this._detector) {
      throw new Error("The detector is not setup");
    }

    const startTimeMs = performance.now();
    if (this._videoElement.currentTime !== this._lastVideoTime) {
      this._lastVideoTime = this._videoElement.currentTime;
    }

    const {
      detections,
    } = this._detector.detectForVideo(this._videoElement, startTimeMs);

    const cameraReading = this.processFaces(detections, this._canvasElement);
    return cameraReading;
  }
 
  private processFaces(detections: Detection[], canvasElement: HTMLCanvasElement): ICameraReading {
    this._context.clearRect(0, 0, this._canvasElement.width, this._canvasElement.height); // Clear the canvas.
    this._context.drawImage(this._videoElement, 0, 0, this._canvasElement.width, this._canvasElement.height);
    if (detections?.length == 0) {
      return {
        date: new Date(),
        is_face_detected: false,
      };
    }

    const prediction = detections[0];
    const {
      originX,
      originY,
      width,
      height,
    } = prediction.boundingBox;

    // Draw the bounding box
    this._context.beginPath();
    this._context.lineWidth = 4;
    this._context.strokeStyle = "blue";
    this._context.rect(originX, originY, width, height);
    this._context.stroke();

        // Draw keypoints if available
    if (prediction.keypoints) {
      prediction.keypoints.forEach((keypoint) => {
        const {
          x,
          y,
        } = keypoint;

        this._context.beginPath();
        this._context.arc(x, y, 2, 0, 2 * Math.PI);
        this._context.fillStyle = "red";
        this._context.fill();
      });
    }
        
    const confidenceThreshold = environmentVariablesService.getCameraDetectionConfidenceThreshold();
    const isPositiveScore: boolean = Math.round(prediction.categories[0].score * 100) >= confidenceThreshold;
    const canvas_frame: string = this._shouldSaveCanvasFrames
      ? canvasElement.toDataURL("image/webp", 0.8)
      : undefined;

    return {
      canvas_frame,
      confidence_value: Math.round(prediction.categories[0].score * 100),
      date: new Date(),
      is_face_detected: isPositiveScore,
    }
  }
};

export default BlazeFaceDetector;
