// Constants
import { sessionStorageKeys } from "@/scripts/constant-types/localStorageKeys";

// Hooks
import useSessionStorage from "@/hooks/useSessionStorage";

// Node Modules
import React, {
  KeyboardEvent,
  createContext,
  useContext,
  useRef,
  useState,
} from "react";

// Types
import IKioskUserSessionContext from "@/interfaces/context/kiosk-user-session/IKioskUserSessionContext";
import IKioskUserSessionContextProvider from "@/interfaces/context/kiosk-user-session/IKioskUserSessionContextProvider";

const KioskUserSessionContext = createContext<IKioskUserSessionContext | undefined>(undefined);

const useKioskUserSessionContext = () => {
  const context = useContext(KioskUserSessionContext);
  if (context === undefined) {
    throw new Error("useKioskUserSessionContext must be used within a KioskUserSessionContextProvider");
  }
  return context;
};

const KioskUserSessionContextProvider = ({
  children,
}: IKioskUserSessionContextProvider) => {
  const [sessionUserId, setSessionUserId] = useSessionStorage<string>(sessionStorageKeys.gaSessionUserId, "", false);
  const [showAttractionLoop, setShowAttractionLoop] = useState<boolean>(false);
  const closeAttractionLoopRef = useRef<((_event?: KeyboardEvent<HTMLDivElement>) => Promise<void>) | undefined>(undefined);
  const isKioskUserSessionOn: boolean = sessionUserId != "";

  const contextValues: IKioskUserSessionContext = {
    closeAttractionLoopRef,
    showAttractionLoop,
    isKioskUserSessionOn,
    sessionUserId,
    setShowAttractionLoop,
    setSessionUserId,
  };

  return (
    <KioskUserSessionContext.Provider
      value={contextValues}
    >
      {children}
    </KioskUserSessionContext.Provider>
  );
};

export {
  KioskUserSessionContext,
  KioskUserSessionContextProvider,
  useKioskUserSessionContext
};
