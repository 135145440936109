// These IDs correspond to the entryIds in Contentful for each specified page on the Kiosk Space.
export const kioskPageContentEntryIds = {
  errorPage: "69D4dcyzwcma9ec9RjeILV",
  globalLayout: "g9Q9vZrBDMA2KkY01THd5",
  kiosksConfiguration: "4H6vDcpU3QUYMFgfpKNCWT",
  leaseStepOne: "JuDD5n2lgPIIfVSw5PlV1",
  paymentStepOne: "3RsahoEsgzxPgj4AA0anwV",
  paymentStepTwo: "74D7pwihaP1YOeatFKGD2N",
  rentUnit: "6o4jaZ3qk4UgAK8zr4nvPB",
  splashScreen: "fo4yRRFUkXXSO9fudRF2F",
  systemOutages: "2LUfmV3w9x9zn9cqMnEIkP",
  thankYou: "3u4op8d2u6tFLvyrB6QbJk",
  viewFacilityMap: "yF3VP1pwtEZUAPRIPRAki",
  breezeRemotePayment: "2knt0T1wqDjwKU0gwGllqD",
};

// These IDs correspond to the entryIds in Contentful for each specified page on the Web's team Space.
export const webPageContentEntryIds = {
  storageSizeGuide: "2bm9VDrIXCEKsPoxvlV9Cx",
};
