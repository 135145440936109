import css from "styled-jsx/css";

export const getAttractionLoopStyles = () => {
  return css.resolve`
      .video-container{
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
      }

      .video{
        height: 100%;
        width: 100%;
      }
    `;
};
