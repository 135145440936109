// Node Modules
import {
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

// Types
import IBreezeKioskPaymentContext from "@/interfaces/context/payment/IBreezeKioskPaymentContext";
import IBreezeKioskPaymentContextProvider from "@/interfaces/context/payment/IBreezeKioskPaymentContextProvider";
import IStartEventData from "@/interfaces/web-socket-notifications/pusher/breeze-kiosk-payment/IStartEventData";

const BreezeKioskPaymentContext = createContext<IBreezeKioskPaymentContext | undefined>(undefined);

const useBreezeKioskPaymentContext = () => {
  const context = useContext(BreezeKioskPaymentContext);
  if (context === undefined) {
    throw new Error("useBreezeKioskPaymentContext must be used within a BreezeKioskPaymentContextProvider");
  }

  return context;
};

const BreezeKioskPaymentContextProvider = ({
  children,
  value,
}: IBreezeKioskPaymentContextProvider) => {
  const [paymentInformationRequestPayload, setPaymentInformationRequestPayload] = useState<IStartEventData>(value ?? undefined);

  const paymentInformationRequestPayloadValue = useMemo(() => ({
    paymentInformationRequestPayload,
    setPaymentInformationRequestPayload,
  }), [paymentInformationRequestPayload, setPaymentInformationRequestPayload]);

  return (
    <BreezeKioskPaymentContext.Provider
      value={paymentInformationRequestPayloadValue}
    >
      {children}
    </BreezeKioskPaymentContext.Provider>
  );
};

export {
  BreezeKioskPaymentContext,
  BreezeKioskPaymentContextProvider,
  useBreezeKioskPaymentContext,
};
