// Services
import API from "@/functions/axiosExternalInstance";

// Types
import IHardwareComponentLoad from "@/interfaces/hardware-integration-module/IHardwareComponentLoad";
import IHardwareComponentPayload from "@/interfaces/hardware-integration-module/IHardwareComponentPayload";
import IHardwareComponentStatus from "@/interfaces/hardware-integration-module/IHardwareComponentStatus";

// Helpers
import HardwareIntegrationModuleHelper from "@/classes/hardware-integration-module/HardwareIntegrationModuleHelper";

abstract class HardwareComponent extends HardwareIntegrationModuleHelper {
  protected abstract componentName: string
  protected abstract payload: IHardwareComponentPayload
  protected himServiceUrl: string = this.getHimServiceUrl();
  private baseUrls = this.getBaseUrls();

  public getComponentName = (): string => this.componentName;

  public async get<T = any>() {
    const url = `${this.baseUrls.getComponent}${this.componentName}/`;
    const response = (await API.post<T>(url, this.payload));

    return response;
  };

  public async hardReset(): Promise<IHardwareComponentStatus> {
    const url = `${this.baseUrls.hardResetComponent}${this.componentName}/`;
    const response = (await API.post<IHardwareComponentStatus>(url, {}));

    return response.data;
  };

  public async load(): Promise<Boolean> {
    const url = this.baseUrls.loadComponent;
    const response = await API.post<IHardwareComponentLoad>(url, this.payload);

    return response.data.IsSuccess;
  };

  public async status(): Promise<IHardwareComponentStatus> {
    const url = `${this.baseUrls.componentStatus}${this.componentName}/`;
    const response = await API.post<IHardwareComponentStatus>(url, {});

    return response.data;
  };
};

export default HardwareComponent;
