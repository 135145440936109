// Node Modules
import Image from "next/image";

// Types
import ISpinnerProps from "@/interfaces/global-components/animations/spinner/ISpinnerProps";

const Spinner = ({
  className,
}: ISpinnerProps) => {
  return (
    <div
      className={`spinner-container ${className}`}
      data-testid="spinner-container"
    >
      <Image
        alt="Loading..."
        className="spinner"
        height={180}
        src="/assets/gifs/spinner.gif"
        width={180}
      />
    </div>
  );
};

export default Spinner;
