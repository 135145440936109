// Types
import IDialEventData from "@/interfaces/web-socket-notifications/pusher/kiosk-call/IDialEventData";
import IDisconnectEventData from "@/interfaces/web-socket-notifications/pusher/kiosk-call/IDisconnectEventData";
import IKioskCallsChannelSubscriptionProps from "@/interfaces/web-socket-notifications/pusher/IKioskCallsChannelSubscriptionProps";
import PusherChannelSubscription from "@/classes/web-socket-notifications/PusherChannelSubscription";

class KioskCallsChannelSubscription extends PusherChannelSubscription {
  protected _channelName: string = "kiosk-call";
  protected _dialEventAction: Function;
  protected _disconnectEventAction: Function;
  private _eventNames = {
    dial: "dial",
    disconnect: "disconnect",
  };

  constructor(props: IKioskCallsChannelSubscriptionProps) {
    super(props);
    this._dialEventAction = props.dialEventAction;
    this._disconnectEventAction = props.disconnectEventAction;
  }

  private bindDialEvent(): void {
    this._channel.bind(this._eventNames.dial, (data: IDialEventData) => this.eventCallback(data, this._dialEventAction));
  }

  private bindDisconnectEvent(): void {
    this._channel.bind(this._eventNames.disconnect, (data: IDisconnectEventData) => this.eventCallback(data, this._disconnectEventAction));
  }

  private eventCallback(data: IDialEventData | IDisconnectEventData, callback: Function) {
    const twilioAccountNameThatKioskIsConnectedTo = this.getTwilioAccountNameThatKioskIsConnectedTo();
    const isKioskConnectedToTheSameTwilioAccountWhereEventOriginated: boolean = twilioAccountNameThatKioskIsConnectedTo === data?.twilioAccountName;
    const isEventTargetingThisKioskUnit: boolean = data?.storeNumber === this._deviceInformation.storeNumber;

    if (isEventTargetingThisKioskUnit && isKioskConnectedToTheSameTwilioAccountWhereEventOriginated) {
      callback();
    }
  };

  private getTwilioAccountNameThatKioskIsConnectedTo = () => process.env.TWILIO_ACCOUNT_NAME ?? "Development";

  public registerEvents(): void {
    if (this._pusher) {
      this._channel = this._pusher.subscribe(this._channelName);
      this.bindDialEvent();
      this.bindDisconnectEvent();
    }
  };
};

export default KioskCallsChannelSubscription;
