// Node modules
import React from "react";
import dynamic from "next/dynamic";

// Types
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

const DynamicFAIcon = dynamic(
  () =>
    import("@fortawesome/react-fontawesome").then(fa => fa.FontAwesomeIcon),
  {
    ssr: false,
  }
);

const FontAwesomeIcon = (props: FontAwesomeIconProps): React.JSX.Element => (
  <DynamicFAIcon
    {...props}
  />
);

export default React.memo(FontAwesomeIcon);
