// Node Modules
import { useEffect } from "react";

// Components
import Button from "@/gc/button/Button";
import FontAwesomeIcon from "@/gc/icon/FontAwesomeIcon";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

// Themes
import theme from "@/theme";

// Types
import ICenteredModalProps from "@/interfaces/global-components/modals/ICenteredModalProps";

const CenteredModal = ({
  buttonOnClick,
  buttonText,
  children,
  className,
  height,
  icon,
  iconColor,
  iconSize,
  iconPulse = false,
  id,
  isCloseableOnClickAway = true,
  isModalClosable = true,
  message,
  onClose,
  padding,
  testId,
  title,
}: ICenteredModalProps) => {
  const showButton: boolean = !!buttonText && !!buttonOnClick;
  const escapeKey: string = "Escape";

  useEffect(() => {
    const closeOnEscapeKeyDown = (event: KeyboardEvent): void => {
      if (isModalClosable && (event.key === escapeKey) && onClose) {
        onClose();
      }

      return;
    };

    document.body.addEventListener("keydown", closeOnEscapeKeyDown);

    return () => document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
  }, []);

  return (
    <>
      <div
        className={`modal ${className}`}
        data-testid={testId ?? "modal"}
        role="modal"
        onClick={isModalClosable && isCloseableOnClickAway
          ? onClose
          : undefined}
      >
        <div
          className={`modal-content ${className}`}
          onClick={e => e.stopPropagation()}
        >
          <div
            className="modal-header"
            data-testid="modal-header"
          >
            <div className="title-container">
              <h2
                data-testid="title"
                role="modal-title"
              >{title}</h2>
            </div>
            {isModalClosable && (
              <div className={`close-btn-container ${className}`}>
                <span
                  data-testid="close-btn"
                  role="close-button"
                  onClick={onClose}
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="7x"
                  />
                </span>
              </div>
            )}
          </div>
          <div className={`modal-body ${className}`}>
            {message && (
              <p
                data-testid="message"
                role="modal-message"
              >{message}</p>
            )}
            {icon && (
              <span
                className="main-icon"
                data-testid="main-icon"
              >
                <FontAwesomeIcon
                  color={iconColor}
                  icon={icon}
                  pulse={iconPulse}
                  size={iconSize}
                />
              </span>
            )}
            {children}
            {showButton && (
              <div className={`button-container ${className}`}>
                <Button
                  id={id}
                  testId="button"
                  title={buttonText}
                  width="100%"
                  onClick={buttonOnClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <style jsx>
        {`            
            h2 {
              text-align: center;
            }

            p {
              text-align: center;
              padding-top: 3%;
              padding-bottom: ${icon
          ? "15%"
          : "7%"};
            }
            .button-container {
              bottom: 0%;
              position: absolute;
              width:100%;
            }

            .close-btn-container {
              font-size: 1rem;
              color: ${theme.brandColors.black};
              width: 10%;
            }

            .main-icon {
              font-size: 5rem;
            }

            .modal {
              position: fixed;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              background-color: rgba(151, 151, 151, 0.5);
              backdrop-filter: blur(10px);
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 5;
            }

            .modal-body {
              position:relative;
              min-height: 87%;
            }

            .modal-content {
              background-color: ${theme.brandColors.white};
              border-radius: 11px;
              height: ${height ?? "715px"};
              text-align: center;
              width: 87%;
              padding: ${padding ?? "6%"};
            }

            .modal-header {
              display: flex;
              flex-wrap: nowrap;
            }

            .title-container { 
              margin-left:10%;
              width: 80%;
            }
            
        `}
      </style>
    </>
  );
};

export default CenteredModal;
