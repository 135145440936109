// Services
import {
  StatusType,
  logOnDataDog
} from "@/services/dataDogLoggingService";

// Types
import HardwareComponent from "@/classes/hardware-integration-module/HardwareComponent";
import IDeviceInformation from "@/interfaces/device/IDeviceInformation";
import IHardwareComponentStatus from "@/interfaces/hardware-integration-module/IHardwareComponentStatus";

const errors = {
  componentNotFound: "Component not found",
}

const loadHardwareComponents = async (components: Array<HardwareComponent>, deviceInformation: IDeviceInformation) => {
  for (const component of components) {
    await triggerActionIfComponentIsOffline(component, () => loadComponent(component));
    await triggerActionIfComponentIsOffline(component, () => hardResetComponent(component, deviceInformation));
  }
}

const triggerActionIfComponentIsOffline = async (component: HardwareComponent, callBackFunction: () => Promise<void>) => {
  const isComponentOffline: boolean = await isComponentStatusOffline(component);
  if (isComponentOffline) {
    await callBackFunction();
  }
}

const loadComponent = async (component: HardwareComponent): Promise<void> => {
  await component.load();
};

const hardResetComponent = async (component: HardwareComponent, deviceInformation: IDeviceInformation): Promise<void> => {
  const componentName: string = component.getComponentName();
  logInformationMessage(componentName, deviceInformation);

  const componentStatus = await component.hardReset();
  if (isStatusOffline(componentStatus)) {
    logErrorMessage(componentName, deviceInformation);
  }
}

const isComponentStatusOffline = async (component: HardwareComponent): Promise<boolean> => {
  const componentStatus: IHardwareComponentStatus = await component.status();
  return isStatusOffline(componentStatus);
};

const isStatusOffline = (componentStatus: IHardwareComponentStatus): boolean => {
  return !componentStatus
    || (!componentStatus.Result && !componentStatus.Status)
    || componentStatus.Result?.Message === errors.componentNotFound
    || componentStatus.Status?.State === "Offline";
}

const logInformationMessage = (componentName: string, deviceInformation: IDeviceInformation): void => {
  const {
    storeNumber,
    ozStoreId,
  } = deviceInformation;

  const error: string = `Component HardReset performed, StoreNumber: ${storeNumber}, OzStoreId: ${ozStoreId}, ComponentName: ${componentName}`;
  logOnDataDog(error, StatusType.info);
};

const logErrorMessage = (componentName: string, deviceInformation: IDeviceInformation): void => {
  const {
    storeNumber,
    ozStoreId,
  } = deviceInformation;

  const error: string = `Component failed to load after Hard Reset was attempted, StoreNumber: ${storeNumber}, OzStoreId: ${ozStoreId}, ComponentName: ${componentName}`;
  logOnDataDog(error, StatusType.error);
};

export default {
  isComponentStatusOffline,
  loadHardwareComponents,
}
