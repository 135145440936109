// Node Modules
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

// Types
import IKeyboardContext from "@/interfaces/context/keyboard/IKeyboardContext";
import IKeyboardContextProvider from "@/interfaces/context/keyboard/IKeyboardContextProvider";
import OnScreenKeyboard from "@/classes/keyboard/OnScreenKeyboard";

const KeyboardContext = createContext<IKeyboardContext | undefined>(undefined);
const KeyboardState = new OnScreenKeyboard();

const useKeyboardContext = () => {
  const context = useContext(KeyboardContext);
  if (!context) {
    throw new Error("useKeyboardContext must be used within a KeyboardContextProvider");
  }

  return context;
};

const KeyboardContextProvider = ({
  children,
  value,
}: IKeyboardContextProvider) => {
  const [Keyboard, setKeyboard] = useState(value?.Keyboard ?? KeyboardState);

  const KeyboardValue = useMemo(() => ({
    Keyboard,
    setKeyboard,
  }), [Keyboard, setKeyboard]);

  return (
    <KeyboardContext.Provider
      value={KeyboardValue}
    >
      {children}
    </KeyboardContext.Provider>
  );
};

export {
  KeyboardContext,
  KeyboardContextProvider,
  useKeyboardContext,
};
