// Utils
import { environments } from "@/scripts/kioskUtil";

class HardwareIntegrationModuleHelper {
  /**
   *
   * @returns an object with the different endpoints that are being used
   * to interact with the different hardware components in the HIM service.
   */
  public getBaseUrls = () => {
    const himServiceUrl: string = this.getHimServiceUrl();
    const baseUrls = {
      getComponent: `${himServiceUrl}Get/`,
      hardResetComponent: `${himServiceUrl}HardReset/`,
      loadComponent: `${himServiceUrl}Load/`,
      componentStatus: `${himServiceUrl}Status/`,
    };

    return baseUrls;
  };

  /**
   *
   * @returns the url of the HIM service, depending on whether we need the mocked version
   * or the real service implementation.
   */
  public getHimServiceUrl = (): string => {
    const apiRouteSuffix = "/api/him-service-testing/";
    const useMockedService = process.env.KIOSK_HIM_API_USE_MOCKED_VERSION === "true"
      && process.env.NEXT_PUBLIC_VERCEL_ENV !== environments.production;

    const isLocalhost = process.env.NEXT_PUBLIC_VERCEL_ENV !== environments.production && process.env.NEXT_PUBLIC_VERCEL_ENV !== environments.preview;

    if (useMockedService) {
      return isLocalhost
        ? `${process.env.NEXT_PUBLIC_VERCEL_URL}${apiRouteSuffix}`
        : `https://${process.env.NEXT_PUBLIC_VERCEL_URL}${apiRouteSuffix}`;
    }

    return process.env.KIOSK_HIM_API;
  };
};

export default HardwareIntegrationModuleHelper;
