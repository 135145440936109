const dataLayerTypes = {
  events: {
    agentPushedPaymentScreen: "agent_pushed_payment_screen",
    audio_cue_played: "audio_cue_played",
    easyPayOptIn: "easy_pay_opt_in",
    bluetoothTooltipOpen: "bluetooth_tooltip_open",
    facilityMapViewOpened: "facility_map_view_opened",
    findUnitInputEvent: "find-unit-input-event",
    filtersCleared: "filters_cleared",
    fontSizeChanged: "font_size_changed",
    interactionCall: "interaction_call",
    listViewPageOpened: "list_view_page_opened",
    listViewUnitClassCardTapped: "list_view_unit_class_card_tapped",
    kioskAudioCallEnded: "kiosk_audio_call_ended",
    kioskOutboundPhoneCall: "kiosk_outbound_phone_call",
    kioskOutboundVideoCall: "kiosk_outbound_video_call",
    kioskVideoCallAnswered: "kiosk_video_call_answered",
    kioskVideoCallEnded: "kiosk_video_call_ended",
    kioskInboundVideoCall: "kiosk_inbound_video_call",
    paymentCancelledOnKioskByAgent: "payment_cancelled_on_kiosk_by_agent",
    paymentCancelledOnKioskByCustomer: "payment_cancelled_on_kiosk_by_customer",
    paymentCompletedOnKioskByCustomer: "payment_completed_on_kiosk_by_customer",
    paymentErrorMessageOnKiosk: "payment_error_message_on_kiosk",
    rentalIdPopulated: "rental_id_populated",
    sessionEnded: "session_ended",
    sessionStarted: "session_started",
    sizeFiltersApplied: "size_filters_applied",
    supportModalOnActionClick: "support_modal_on_action_click",
    supportModalOnDismissClick: "support_modal_on_dismiss_click",
    timerExpired: "timer_expired",
    unitCallCardTapped: "unit_call_card_tapped",
    uniSelectionListLoadMore: "unit_selection_list_load_more",
    unitSelectedModalRentThisUnitTapped: "unit_selected_modal_rent_this_unit_tapped",
    unitSelectedModalKeepBrowsingTapped: "unit_selected_modal_keep_browsing_tapped",
  },
  pageTypes: {
    error404: "404-error",
    error500: "500-error",
  },
  siteTypes: {
    default: "ESS_2.0",
  },
};

export default dataLayerTypes;
