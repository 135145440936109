// Enums
import { CustomerSupportFeature } from "@/enums/optimizely/CustomerSupportFeature";

const resetHasAttractionLoopJustBeenClosedIfCallIsActive = (
  isAudioCallStateActive: boolean,
  isVideoCallStateActive: boolean,
  resetHasAttractionLoopJustBeenClosed: () => void
): void => {
  if (isAudioCallStateActive || isVideoCallStateActive) {
    resetHasAttractionLoopJustBeenClosed();
  }
}

const validateIfInteractionCallFeatureIsEnabled = (
  customerSupportFeature: string,
  hasInteractionCallBeenTriggeredForThisSession: boolean,
  isAudioCallStateActive: boolean,
  isNSCCurrentlyOpen: boolean,
  isVideoCallStateActive: boolean,
  isStoreOutsideOfStaffingHours: boolean
): boolean => {
  const isCustomerSupportAvailable = isNSCCurrentlyOpen;
  const areCallsInactive = !isAudioCallStateActive && !isVideoCallStateActive;
  const isFeatureEnabled = !!customerSupportFeature && customerSupportFeature === CustomerSupportFeature.InteractionCall;

  return !hasInteractionCallBeenTriggeredForThisSession
  && isCustomerSupportAvailable
  && areCallsInactive
  && isFeatureEnabled
  && isStoreOutsideOfStaffingHours;
};

const validateIfSupportModalFeatureIsEnabled = (
  hasAttractionLoopJustBeenClosed: boolean,
  isAudioCallStateActive: boolean,
  isNSCCurrentlyOpen: boolean,
  customerSupportFeature: string,
  isVideoCallStateActive: boolean
): boolean => {
  const isCustomerSupportAvailable = isNSCCurrentlyOpen;
  const areCallsInactive = !isAudioCallStateActive && !isVideoCallStateActive;
  const isFeatureEnabled = !!customerSupportFeature && customerSupportFeature === CustomerSupportFeature.TADModal;

  return hasAttractionLoopJustBeenClosed
  && isCustomerSupportAvailable
  && areCallsInactive
  && isFeatureEnabled;
};

export {
  resetHasAttractionLoopJustBeenClosedIfCallIsActive,
  validateIfInteractionCallFeatureIsEnabled,
  validateIfSupportModalFeatureIsEnabled,
};

