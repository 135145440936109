// Scripts
import { storeInformationStorageKey } from "./constant-types/localStorageKeys";

// Services
import { setLocalStorageObject } from "@/services/localStorageService";

// Types
import { IDefaultStoreScheduleModified } from "../models/contentful/IDefaultStoreScheduleModified";
import IHoursOfOperationDetails from "@/interfaces/splash-screen/IHoursOfOperationDetails";
import { IIntervalScheduleChecksResult } from "@/interfaces/utils/IIntervalScheduleChecksResult";
import {
  IHoursOfOperation,
  IStoreSchedule
} from "@/interfaces/hours-of-operation/IHoursOfOperation";

export const nscHoursOfOperationType: string = "NSC_Customer_Support";

const sorter = [
  {
    key: "monday",
    label: "Monday",
    sortOrder: 1,
  },
  {
    key: "tuesday",
    label: "Tuesday",
    sortOrder: 2,
  },
  {
    key: "wednesday",
    label: "Wednesday",
    sortOrder: 3,
  },
  {
    key: "thursday",
    label: "Thursday",
    sortOrder: 4,
  },
  {
    key: "friday",
    label: "Friday",
    sortOrder: 5,
  },
  {
    key: "saturday",
    label: "Saturday",
    sortOrder: 6,
  },
  {
    key: "sunday",
    label: "Sunday",
    sortOrder: 7,
  },
];

const getLabel = (length: number, labels: string[]) => {
  return length > 1
    ? `${labels[0]}-${labels[length - 1]}`
    : labels[0]
};

const groupHoursOfOperation = (hoursOfOperationType: IHoursOfOperationDetails, twentyFourHoursLabel: string): IHoursOfOperation[] => {
  let hours = hoursOfOperationType[sorter[0].key];
  let labels = [sorter[0].label];
  const groupedHours: IHoursOfOperation[] = [];

  for (let i = 1; i < sorter.length; i++) {
    const day = sorter[i];
    if (hours === hoursOfOperationType[day.key]) {
      labels.push(day.label);
    } else {
      const {
        length,
      } = labels;

      groupedHours.push({
        hours,
        label: getLabel(length, labels),
      });

      hours = hoursOfOperationType[day.key];
      labels = [day.label];
    }
  }

  const {
    length,
  } = labels;

  groupedHours.push({
    hours,
    label: getLabel(length, labels),
  });

  const formattedGroupedHours = groupedHours.reduce((acc, obj) => {
    if (obj.hours === "12:00 AM - 12:00 PM" && twentyFourHoursLabel) {
      const modifiedObj = {
        ...obj,
        hours: twentyFourHoursLabel,
      };

      acc.push(modifiedObj);
    } else {
      acc.push({
        ...obj,
        hours: obj.hours?.replace(/^0+/, ""),
      });
    }
    return acc;
  }, []);

  return formattedGroupedHours;
};

export const getHoursOfOperationByType = (hoursOfOperation: IHoursOfOperationDetails[], type: string): IHoursOfOperationDetails => {
  let hoursOfOperationType: IHoursOfOperationDetails;
  hoursOfOperation.forEach(hours => {
    if (hours.type.toLowerCase() === type.toLowerCase()) {
      hoursOfOperationType = hours;
    }
  });

  return hoursOfOperationType;
};

export const getAllFormattedHoursOfOperation = (hoursOfOperation: IHoursOfOperationDetails[], twentyFourHoursLabel: string = ""): IStoreSchedule => {
  const staffingHours = getHoursOfOperationByType(hoursOfOperation, "staffing");
  const formattedStaffingHours = staffingHours
    ? groupHoursOfOperation(staffingHours, twentyFourHoursLabel)
    : [];

  const gateHours = getHoursOfOperationByType(hoursOfOperation, "gate");
  const formattedGateHoursHours = gateHours
    ? groupHoursOfOperation(gateHours, twentyFourHoursLabel)
    : [];

  const phoneHours = getHoursOfOperationByType(hoursOfOperation, nscHoursOfOperationType);
  const formattedPhoneHoursHours = phoneHours
    ? groupHoursOfOperation(phoneHours, twentyFourHoursLabel)
    : [];

  return {
    gate: formattedGateHoursHours,
    phone: formattedPhoneHoursHours,
    staffing: formattedStaffingHours,
  };
};

export const getNscContentfulDefaultHours = (contentfulDefaultStoreSchedule: IDefaultStoreScheduleModified): IHoursOfOperationDetails => {
  const hoursOfOperation: IHoursOfOperationDetails = {
    friday: "",
    monday: "",
    saturday: "",
    sunday: "",
    thursday: "",
    tuesday: "",
    type: "",
    wednesday: "",
    timeZone: "",
  };

  contentfulDefaultStoreSchedule.fields.nscCustomerSupport.forEach((scheduleRow) => {
    const day: string = scheduleRow.fields.label as string;
    hoursOfOperation[day] = scheduleRow.fields.hours;
  });

  return {
    ...hoursOfOperation,
    type: nscHoursOfOperationType,
    timeZone: "America/Denver",
  }
};

/**
 *
 * @param hoursOfOperation The store hours of operation that come from the API.
 * @param defaultStoreSchedule The contentful default store schedule to show if the API hours of operation are not available.
 * @param twentyFourHoursHeading The value to show when there is a 24 hour schedule.
 */
export const setFormattedHoursOfOperationInLocalStorage = (
  hoursOfOperation: IHoursOfOperationDetails[],
  defaultStoreSchedule: IDefaultStoreScheduleModified,
  twentyFourHoursHeading?: string
): void => {
  const transformContentfulDefaultHours = (contentfulDefaultStoreSchedule: IDefaultStoreScheduleModified): IStoreSchedule => {
    return {
      staffing: [
        ...contentfulDefaultStoreSchedule.fields.staffing.map((scheduleRow) => {
          return {
            hours: scheduleRow.fields.hours,
            label: scheduleRow.fields.label,
          }
        }),
      ],
      gate: [
        ...contentfulDefaultStoreSchedule.fields.gate.map((scheduleRow) => {
          return {
            hours: scheduleRow.fields.hours,
            label: scheduleRow.fields.label,
          }
        }),
      ],
    }
  };

  if (hoursOfOperation && twentyFourHoursHeading) {
    const formattedHoursOfOperation: IStoreSchedule = getAllFormattedHoursOfOperation(hoursOfOperation, twentyFourHoursHeading);
    setLocalStorageObject(storeInformationStorageKey, formattedHoursOfOperation);
  } else {
    setLocalStorageObject(storeInformationStorageKey, transformContentfulDefaultHours(defaultStoreSchedule));
  }
};

/**
 *
 * @param everyXMinutesOfAnHour the minutes of an hour where the interval should run
 */
export const calculateTimeOutForIntervalScheduleChecks = (everyXMinutesOfAnHour: number): IIntervalScheduleChecksResult => {
  if (everyXMinutesOfAnHour > 60 || everyXMinutesOfAnHour < 1) {
    return {
      intervalTimeInMilliseconds: 0,
      timeOutForIntervalStart: 0,
    }
  }

  const intervalTimeInMilliseconds = everyXMinutesOfAnHour * 60 * 1000;
  const currentDate = new Date();

  const currentMinutes = currentDate.getMinutes();
  const currentSeconds = currentDate.getSeconds();
  const currentMilliseconds = currentDate.getMilliseconds();

// Calculate the minutes that need to pass until interval can start to just run every x minutes of an hour
// IE:  current time: 1:11PM
//      every_X_Minutes_of_an_hour: 30 (interval needs to run every 30min of an hour)
//      minutesUntilNextInterval = 19min  This means that at 1:30PM  the interval will start and run every 30min, which means every 30min of an hour
  const minutesUntilNextInterval = everyXMinutesOfAnHour - (currentMinutes % everyXMinutesOfAnHour);

// Calculate the exact time for the interval to start. Basically is minutesUntilNextInterval with the precision of milliseconds
// IE   using previous example timeOutForIntervalStart: 1095454(milliseconds) which equals 18.257566666666666 min
//      If time is 1:11PM then the interval will start in 1095454 milliseconds, which will be 1:30:00:00PM
  const timeOutForIntervalStart = (minutesUntilNextInterval * 60 * 1000) - (currentSeconds * 1000) - currentMilliseconds;

  return {
    intervalTimeInMilliseconds,
    timeOutForIntervalStart,
  }
}

