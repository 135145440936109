/**
 * This file exposes an axios instance configured to make external API calls that DO NOT require a token.
 */

// Node Modules
import axios, {
  AxiosRequestConfig,
  AxiosResponse
} from "axios";

const resolveIfStatusCodeIsLessThan500 = (status: number) => status < 500;

const instance = axios.create({
  validateStatus: (status: number) => resolveIfStatusCodeIsLessThan500(status),
});

instance.interceptors.request.use(async config => {
  const headers = {
    "Content-Type": "application/json",
  };

  // set `instance`'s config
  return Object.assign({}, config, {
    headers: Object.assign({}, headers, config.headers || {}),
  });
});

/**
* issue a GET request
* @param url url to send request to
* @param config any config overrides (optional)
*/
async function get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  return await instance.get<T>(url, config);
}

/**
 * issue a POST request
 * @param url url to send request to
 * @param payload data for the body of the request
 * @param config any config overrides (optional)
 */
async function post<T = any>(url: string, payload?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  return await instance.post<T>(url, payload, config);
}

const isNetworkError = error => !!error
  && (error.message === "Network Error" || error.message.includes("timeout")) || error.message.includes("connect ECONNREFUSED");

export default {
  get,
  post,
};

export { isNetworkError };
