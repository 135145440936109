// Node Modules
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

// Scripts
import { isCurrentTimeWithin30MinutesRange } from "@/scripts/dateHelper";

// Types
import { IAttractionLoopInfoFields } from "@/contentful-types";
import ISystemOutageContext from "@/interfaces/context/system-outages/ISystemOutageContext";
import ISystemOutageContextProvider from "@/interfaces/context/system-outages/ISystemOutageContextProvider";

const SystemOutageContext = createContext<ISystemOutageContext | undefined>(undefined);

const useSystemOutageContext = (): ISystemOutageContext => {
  const context = useContext(SystemOutageContext);
  if (context === undefined) {
    throw new Error("useSystemOutageContext must be used within a SystemOutageContextProvider");
  }

  return context;
};

const SystemOutageContextProvider = ({
  children,
}: ISystemOutageContextProvider) => {
  const [attractionLoopVideoInformation, setAttractionLoopVideoInformation] = useState<IAttractionLoopInfoFields>();
  const [showSystemOutageBanner, setShowSystemOutageBanner] = useState<boolean>(false);

  const isSystemOutageScheduledForTheNext30Minutes = (attractionLoop?: IAttractionLoopInfoFields): boolean => {
    attractionLoop = attractionLoop ?? attractionLoopVideoInformation;

    const startDateTime: Date = attractionLoop?.startDateTime
      ? new Date(attractionLoop.startDateTime)
      : undefined;

    return isCurrentTimeWithin30MinutesRange(startDateTime);
  };

  const value: ISystemOutageContext = useMemo(() => ({
    attractionLoopVideoInformation,
    isSystemOutageScheduledForTheNext30Minutes,
    setAttractionLoopVideoInformation,
    setShowSystemOutageBanner,
    showSystemOutageBanner,
  }), [attractionLoopVideoInformation, showSystemOutageBanner]);

  return (
    <SystemOutageContext.Provider
      value={value}
    >
      {children}
    </SystemOutageContext.Provider>
  );
};

export {
  SystemOutageContext,
  SystemOutageContextProvider,
  useSystemOutageContext,
};
