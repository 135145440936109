import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState
} from "react";

export default function useSessionStorage<T = any>(key: string, initialValue: T, forceUpdate?: boolean): [T, Dispatch<SetStateAction<T>>] {
  let sessionStorageValue: string;
  if (typeof window !== "undefined") {
    sessionStorageValue = window.sessionStorage.getItem(key);
  }

  let value: T;
  try {
    value = sessionStorageValue && !forceUpdate
      ? JSON.parse(sessionStorageValue)
      : initialValue;
  } catch {
    value = initialValue;
  }

  const [storedValue, setStoredValue] = useState<T>(value);

  useEffect(() => {
    try {
      window.sessionStorage.setItem(key, JSON.stringify(storedValue));
    } catch (error) {
      console.log(error);
    }
  }, [storedValue, key]);

  return [storedValue, setStoredValue];
}
