// Types
import IBreezeKioskPaymentsChannelSubscriptionProps from "@/interfaces/web-socket-notifications/pusher/IBreezeKioskPaymentsChannelSubscriptionProps";
import ICancelEventData from "@/interfaces/web-socket-notifications/pusher/breeze-kiosk-payment/ICancelEventData";
import IStartEventData from "@/interfaces/web-socket-notifications/pusher/breeze-kiosk-payment/IStartEventData";
import PusherChannelSubscription from "@/classes/web-socket-notifications/PusherChannelSubscription";

class BreezeKioskPaymentsChannelSubscription extends PusherChannelSubscription {
  protected _channelName: string = "breeze-kiosk-payment";
  protected _startEventAction: (_data: IStartEventData) => void;
  protected _cancelEventAction: () => void;
  private _eventNames = {
    start: "start",
    cancel: "cancel",
  };

  constructor(props: IBreezeKioskPaymentsChannelSubscriptionProps) {
    super(props);
    this._startEventAction = props.startEventAction;
    this._cancelEventAction = props.cancelEventAction;
  }

  private bindStartEvent(): void {
    this._channel.bind(this._eventNames.start, (data: IStartEventData) => this.eventCallback(data, () => this._startEventAction(data)));
  }

  private bindCancelEvent(): void {
    this._channel.bind(this._eventNames.cancel, (data: ICancelEventData) => this.eventCallback(data, this._cancelEventAction));
  }

  private eventCallback(data: IStartEventData | ICancelEventData, callback: Function) {
    const isEventTargetingThisKioskUnit: boolean = data?.kioskId === this._deviceInformation.kioskId;

    if (isEventTargetingThisKioskUnit) {
      callback();
    }
  };

  public registerEvents(): void {
    if (this._pusher) {
      this._channel = this._pusher.subscribe(this._channelName);
      this.bindStartEvent();
      this.bindCancelEvent();
    }
  };
};

export default BreezeKioskPaymentsChannelSubscription;
