// Constants
import dataLayerTypes from "@/scripts/constant-types/google-analytics/dataLayerTypes";

// Context
import { useCustomerDetectionContext } from "@/context/CustomerDetectionContext";
import { useKioskUserSessionContext } from "@/context/KioskUserSessionContext";

// Enums
import { AudioCueFeature } from "@/enums/optimizely/AudioCueFeature";

// Node Modules
import { useDecision } from "@optimizely/react-sdk";
import {
  useEffect,
  useRef,
  useState,
} from "react";

// Scripts
import { dataDogLogMessages } from "@/scripts/constant-types/logging/dataDogLogMessages";
import { kioskPageContentEntryIds } from "@/scripts/constant-types/pageContent";

// Services
import contentService from "@/services/contentService";
import dataLayerService from "@/services/dataLayerService";
import {
  StatusType,
  logOnDataDog
} from "@/services/dataDogLoggingService";

// Types
import { Data } from "@/src/models/Data";
import { IAudioBucket } from "@/contentful-types";
import IAudioCueHandlerProps from "@/interfaces/audio-cue/IAudioCueHandlerProps";
import { ISplashScreenFieldsModified } from "@/interfaces/splash-screen";
import { useSchedulesContext } from "@/context/SchedulesContext";

const AudioCueHandler = ({
  customerDetectionEvent,
}: IAudioCueHandlerProps) => {
  const [attractionLoopAudios, setAttractionLoopAudios] = useState<Data<IAudioBucket>[]>();
  const justPlayedAudio = useRef<boolean>(false);
  const resetPlayAudioTimeOut = useRef<NodeJS.Timeout | null>(null);
  const splashScreenEntryId: string = kioskPageContentEntryIds.splashScreen;

  const [decision] = useDecision(AudioCueFeature.Variation, {
    autoUpdate: true,
  });

  const {
    isKioskUserSessionOn,
    showAttractionLoop,
  } = useKioskUserSessionContext();

  const {
    shouldPlayAudio,
  } = useCustomerDetectionContext();

  const {
    isStaffingCurrentlyOpen,
  } = useSchedulesContext();

  useEffect(() => {
    if (decision && decision.enabled) {
      if (shouldPlayAudio && !justPlayedAudio.current && !isKioskUserSessionOn && !isStaffingCurrentlyOpen && showAttractionLoop) {
        if (attractionLoopAudios && attractionLoopAudios.length) {
          const audioFile = attractionLoopAudios.find(x => x.fields?.id === decision.variationKey);

          if (audioFile) {
            const audio = new Audio(audioFile?.fields?.audioFile?.fields?.file?.url);
            audio.addEventListener("ended", resetAudioCueLogic);
            justPlayedAudio.current = true;
            audio.play()
              .then(logThatAudioWasPlayed)
              .catch(audioPlayOnError);
          }
        }
      }
    }
  }, [
    decision,
    isStaffingCurrentlyOpen,
    shouldPlayAudio,
    attractionLoopAudios,
  ]);

  const logThatAudioWasPlayed = (): void => {
    logOnDataDog(dataDogLogMessages.audioCue.audioPlayed, StatusType.info, {
      event: customerDetectionEvent,
    });

    dataLayerService.pushEvent({
      event: dataLayerTypes.events.audio_cue_played,
    });
  };

  const resetAudioCueLogic = (): void => {
    logOnDataDog(dataDogLogMessages.audioCue.audioEnded, StatusType.info, {
      event: customerDetectionEvent,
    });
    resetPlayAudioTimeOut.current = setTimeout(() => {
      justPlayedAudio.current = false;
    }, 1000 * 60);
  };

  const audioPlayOnError = (error: any) => {
    logOnDataDog(dataDogLogMessages.audioCue.audioError, StatusType.error, {
      event: customerDetectionEvent,
      error,
    });

    resetAudioCueLogic();
  };

  useEffect(() => {
    getContenfulData();
    return () => {
      cleanComponentTimeOut();
    }
  }, []);

  const getContenfulData = async (): Promise<void> => {
    const kioskContent: ISplashScreenFieldsModified = await contentService.getKioskContent(splashScreenEntryId);
    setAttractionLoopAudios(kioskContent.attractionLoopAudios);
  };

  const cleanComponentTimeOut = () => {
    if (resetPlayAudioTimeOut.current) {
      clearTimeout(resetPlayAudioTimeOut.current);
    }
  }

  return (
    <div id="audio-cue" />
  );
}

export default AudioCueHandler;
