// Node Modules
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

// Types
import ITimerContext from "@/interfaces/context/timer/ITimerContext";
import ITimerContextProvider from "@/interfaces/context/timer/ITimerContextProvider";

const TimerContext = createContext<ITimerContext | undefined>(undefined);

const useTimerContext = () => {
  const context = useContext(TimerContext);
  if (context === undefined) {
    throw new Error("useTimerContext must be used within a TimerContextProvider");
  }
  return context;
};

const TimerContextProvider = ({
  children,
  isTimerEnabledInitialValue = true,
}: ITimerContextProvider) => {
  const [isTimerEnabled, setIsTimerEnabled] = useState<boolean>(isTimerEnabledInitialValue);
  const enableTimer = (): void => setIsTimerEnabled(true);
  const disableTimer = (): void => setIsTimerEnabled(false);

  const contextValues: ITimerContext = useMemo(() => ({
    disableTimer,
    enableTimer,
    isTimerEnabled,
  }), [isTimerEnabled]);

  return (
    <TimerContext.Provider
      value={contextValues}
    >
      {children}
    </TimerContext.Provider>
  );
};

export {
  TimerContext,
  TimerContextProvider,
  useTimerContext
};
