// Helpers
import { getAttractionLoopInfoByStoreNumberOrBrand } from "@/scripts/kioskUtil";
import { isCurrentDateWithinRange } from "@/scripts/dateHelper";

// Node Modules
import React from "react";

// Types
import { Data } from "@/src/models/Data";
import IDeviceInformation from "@/interfaces/device/IDeviceInformation";
import { ISplashScreenFieldsModified } from "@/interfaces/splash-screen/ISplashScreenFieldsModified";
import {
  IAttractionLoopInfo,
  IAttractionLoopInfoFields,
} from "@/contentful-types";

// Utils
import {
  addMinutesToDate,
  getDate
} from "@/scripts/dateHelper";

/**
 * @param content An object from contentful of type ISplashScreenFieldsModified.
 * @returns The IAttractionLoopInfoFields object for system outages or undefined if it does not exist in contentful.
 */
export const getSystemOutageAttractionLoopInformation = (content: ISplashScreenFieldsModified, deviceInformation: IDeviceInformation): IAttractionLoopInfoFields | undefined => {
  const attractionLoops = content?.attractionLoops;

  const systemOutageAttractionLoops: Data<IAttractionLoopInfo>[] = attractionLoops?.filter(attractionLoop => attractionLoop.fields?.key === "OUTAGE");
  if (systemOutageAttractionLoops?.length === 0) {
    return undefined;
  }

  if (systemOutageAttractionLoops.length === 1 && !systemOutageAttractionLoops[0].fields.target) {
    return systemOutageAttractionLoops[0].fields;
  }

  const attractionLoop: Data<IAttractionLoopInfo> = getAttractionLoopInfoByStoreNumberOrBrand(systemOutageAttractionLoops, deviceInformation);

  return attractionLoop?.fields;
};

/**
 * @param attractionLoop An Attraction Loop Video object.
 * @returns Whether the system outage attraction loop is enabled or not.
 */
export const isSystemOutageAttractionLoopVideoEnabled = (attractionLoop: IAttractionLoopInfoFields): boolean => {
  if (!attractionLoop) {
    return false;
  }

  const startDateTime: Date = attractionLoop.startDateTime
    ? new Date(attractionLoop.startDateTime)
    : undefined;

  const endDateTime: Date = attractionLoop.endDateTime
    ? new Date(attractionLoop.endDateTime)
    : undefined;

  return attractionLoop?.key === "OUTAGE" && attractionLoop?.isEnabled && isCurrentDateWithinRange(startDateTime, endDateTime);
};

export const checkSystemOutageBannerStatus = (systemOutageAttractionLoop: IAttractionLoopInfoFields, setShowSystemOutageBanner: React.Dispatch<React.SetStateAction<boolean>>, stopBannerCheck: (_interval: NodeJS.Timeout, _isSystemOutage: boolean) => void): void => {
  const checkOutageBannerInterval: NodeJS.Timeout = setInterval(
    () => {
      const minutes = 15;
      const now = getDate();

      const startDateTime: Date = systemOutageAttractionLoop.startDateTime
        ? new Date(systemOutageAttractionLoop.startDateTime)
        : undefined;

      if (!startDateTime) {
        stopBannerCheck(checkOutageBannerInterval, false);
      }

      const fifteenMinutesBeforeDate = addMinutesToDate(startDateTime, -minutes);

      if (now > startDateTime) {
        setShowSystemOutageBanner(false);
        stopBannerCheck(checkOutageBannerInterval, true);
      }

      if ((now >= fifteenMinutesBeforeDate) && now <= startDateTime) {
        setShowSystemOutageBanner(true);
      }
    },
    parseInt(process.env.SYSTEM_OUTAGES_BANNER_SET_INTERVAL_MS));
};
