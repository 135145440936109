const deviceInformationStorageKey = "DEVICE_INFORMATION";
const featureTogglesStorageKey = "FEATURES";
const proximitySensorThresholdKey = "PROX_SENSOR_THRESHOLD_VALUES";
const storeInformationStorageKey = "STORE_INFORMATION";

const sessionStorageKeys = {
  gaSessionUserId: "sessionUserId",
};

export {
  deviceInformationStorageKey,
  featureTogglesStorageKey,
  proximitySensorThresholdKey,
  sessionStorageKeys,
  storeInformationStorageKey,
};
