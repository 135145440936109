// Node Modules
import React, {
  createContext,
  useContext,
} from "react";

// Types
import { IDeviceInformationContextProps } from "@/interfaces/context/device-information/IDeviceInformationContextProps"
import { IDeviceInformationContextProviderProps } from "@/interfaces/context/device-information/IDeviceInformationContextProviderProps";
import { useDeviceInformationHandler } from "./DeviceInformationHandler";

const DeviceInformationContext = createContext<IDeviceInformationContextProps | undefined>(undefined);

const useDeviceInformationContext = () => {
  const context = useContext(DeviceInformationContext);
  if (context === undefined) {
    throw new Error("useDeviceInformationContext must be used within a DeviceInformationContextProvider");
  }

  return context;
};

const DeviceInformationContextProvider = ({
  children,
  kioskComputerNames,
}: IDeviceInformationContextProviderProps) => {
  const {
    deviceInformation,
  } = useDeviceInformationHandler(kioskComputerNames);

  const deviceInformationValue: IDeviceInformationContextProps = {
    brand: deviceInformation?.brand,
    deviceInformation,
    kioskId: deviceInformation?.kioskId,
    storeNumber: deviceInformation?.storeNumber,
    ozStoreId: deviceInformation?.ozStoreId,
  };

  return (
    <DeviceInformationContext.Provider
      value={deviceInformationValue}
    >
      {children}
    </DeviceInformationContext.Provider>
  );
};

export {
  DeviceInformationContext,
  DeviceInformationContextProvider,
  useDeviceInformationContext,
};
