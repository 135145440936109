// Node Modules
import {
  Dispatch,
  SetStateAction
} from "react";

// Services
import hardwareIntegrationModuleService from "@/components/services/hardwareIntegrationModuleService";
import {
  StatusType,
  logOnDataDog
} from "@/components/services/dataDogLoggingService";

// Types
import IDeviceInformation from "@/interfaces/device/IDeviceInformation";
import IProximitySensorReading from "@/interfaces/customer-detection/proximity-sensor/IProximitySensorReading";
import IProximitySensorThreshold from "@/interfaces/context/proximity-sensor/IProximitySensorThreshold";
import IReadResponse from "@/interfaces/hardware-integration-module/proximity-sensor/IReadResponse";
import ProximitySensor from "@/classes/hardware-integration-module/proximity-sensor/ProximitySensor";

class ProximitySensorHelper {
  private interval: NodeJS.Timeout;
  private readValueFromSensorContinuouslyTimeOut: NodeJS.Timeout;
  private proximitySensor: ProximitySensor;

  constructor() {
    this.proximitySensor = new ProximitySensor();
  }

  public async calibrateProximitySensor(
    setIsAutoCalibrationRunning: React.Dispatch<SetStateAction<boolean>>,
    setProximitySensorThreshold: React.Dispatch<SetStateAction<IProximitySensorThreshold>>,
    counter: number = 0,
    values: number[] = []
  ): Promise<void> {
    const numberOfReadings: number = 20;
    let timeOut: NodeJS.Timeout;
    if (counter == numberOfReadings) {
      clearTimeout(timeOut);
      timeOut = null;

      const maxValue: number = Math.max(...values);
      const bufferToReduceFalseDetections: number = parseInt(process.env.PROXIMITY_SENSOR_DETECTION_THRESHOLD_BUFFER)
      const threshold: IProximitySensorThreshold = {
        calibrationValues: values,
        averageCalibrationValue: maxValue + bufferToReduceFalseDetections,
        calibrationDate: new Date().toString(),
      };

      logOnDataDog("KIOSK_PROX_AUTOCALIBRATION_COMPLETED", StatusType.info, {
        threshold,
      });

      setProximitySensorThreshold(threshold);
      setIsAutoCalibrationRunning(false);
      return;
    }
    
    timeOut = setTimeout(async () => {
      const readResponse: IReadResponse = await this.readValueFromSensor();
      const value: number = readResponse?.Status?.StatusData?.Inputs[0]?.Value;
      values.push(value);
      counter++;
      await this.calibrateProximitySensor(
        setIsAutoCalibrationRunning,
        setProximitySensorThreshold,
        counter++,
        values);
    }, parseInt(process.env.PROXIMITY_SENSOR_DETECTION_SET_INTERVAL_MS));
  };

  public clearProximitySensorInterval() {
    clearInterval(this.interval);
  };
  
  public isKioskConfiguredToUseProximitySensor(deviceInformation: IDeviceInformation): boolean {
    const isPilotUnit: boolean = deviceInformation.kioskModel == "Pilot";
    return !isPilotUnit;
  };

  private async isProximitySensorOnline(): Promise<boolean> {
    const isSensorOffline = await hardwareIntegrationModuleService.isComponentStatusOffline(this.proximitySensor);
    return !isSensorOffline;
  };

  public async isTimeToAutoCalibrateProximitySensor(
    proximitySensorThreshold: IProximitySensorThreshold,
    deviceInformation: IDeviceInformation,
    datadogLogObject?: Object
  ): Promise<boolean> {
    const hasProxSensorBeenCalibratedToday: boolean = !!proximitySensorThreshold?.calibrationDate
      && new Date(proximitySensorThreshold.calibrationDate).toDateString() == new Date().toDateString()
      && proximitySensorThreshold?.averageCalibrationValue > 0;

    datadogLogObject = {
      ...datadogLogObject,
      hasProxSensorBeenCalibratedTodayAlready: hasProxSensorBeenCalibratedToday,
    };

    const isProxSensorEnabledAndOnlineInThisKiosk = await this.isProxSensorEnabledAndOnlineInThisKiosk(deviceInformation, datadogLogObject)

    return isProxSensorEnabledAndOnlineInThisKiosk && !hasProxSensorBeenCalibratedToday;
  };

  public async isProxSensorEnabledAndOnlineInThisKiosk(deviceInformation: IDeviceInformation, datadogLogObject?: any): Promise<boolean> {
    const isProximitySensorOnline: boolean = await this.isProximitySensorOnline();
    const isKioskConfiguredToUseProximitySensor: boolean = this.isKioskConfiguredToUseProximitySensor(deviceInformation);

    if (datadogLogObject) {
      datadogLogObject = {
        ...datadogLogObject,
        isProximitySensorOnline,
        isKioskConfiguredToUseProximitySensor,
        isTimeToAutoCalibrateProximitySensor: isProximitySensorOnline && isKioskConfiguredToUseProximitySensor && !datadogLogObject.hasProxSensorBeenCalibratedTodayAlready,
      };
  
      logOnDataDog("KIOSK_PROX_AUTOCALIBRATION_EVAL", StatusType.info, datadogLogObject);
    }
    
    return isProximitySensorOnline && isKioskConfiguredToUseProximitySensor;
  };

  public async readValueFromSensorContinuously(setProximitySensorReadings: Dispatch<SetStateAction<IProximitySensorReading[]>>): Promise<void> {
    this.readValueFromSensorContinuouslyTimeOut = setTimeout(async () => {
      const readResponse: IReadResponse = await this.readValueFromSensor();
      const value: number = readResponse?.Status?.StatusData?.Inputs[0]?.Value;
      const proxSensorReading: IProximitySensorReading = {
        date: new Date(),
        value,
      };
      
      setProximitySensorReadings(x => [...x, proxSensorReading]);
      await this.readValueFromSensorContinuously(setProximitySensorReadings);
    }, parseInt(process.env.PROXIMITY_SENSOR_DETECTION_SET_INTERVAL_MS));
  };

  public async readValueFromSensor(): Promise<IReadResponse> {
    const readResponse: IReadResponse = await this.proximitySensor.read();
    
    return readResponse;
  };

  public stopReadingValuesFromSensor = (): void => clearTimeout(this.readValueFromSensorContinuouslyTimeOut);
}

export default ProximitySensorHelper;
