const onClickWithDataLayerEvent = (onClick: () => void, dataLayerEventName?: string): void => {
  onClick();
  if (dataLayerEventName) {
    pushEvent({
      event: dataLayerEventName,
    });
  }
};

const getDataLayer = (): any[] => typeof window !== "undefined" && window.dataLayer || [];

const pushEvent = (event: any): any[] => {
  const dataLayer = getDataLayer();
  dataLayer.push(event);

  return dataLayer;
};

const setCustomDimensions = async (ozStoreId: string) => {
  if (ozStoreId) {
    const geoLocation: string = await getGeoLocation();
    const event = {
      event: "kiosk_page_loaded",
      storeCSID: ozStoreId,
      htmlGeoLocation: geoLocation,
      siteType: "ESS_2.0",
    };

    pushEvent(event);
  }
};

const getGeoLocation = async (): Promise<string> => {
  return new Promise((resolve) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        resolve(`${position.coords.latitude}|${position.coords.longitude}`);
      });
    } else {
      resolve("");
    }

    resolveIfLocationPermissionsAreNotAccepted(() => resolve);
  });
};

const resolveIfLocationPermissionsAreNotAccepted = (resolve: () => void) => {
  setTimeout(() => {
    resolve();
  }, 2000);
}

export default {
  onClickWithDataLayerEvent,
  getDataLayer,
  pushEvent,
  setCustomDimensions,
};
