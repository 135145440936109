// Contexts
import { BreezeKioskPaymentContextProvider } from "@/context/BreezeKioskPaymentContext";
import { CallContextProvider } from "@/context/CallContext";
import { CustomerDetectionContextProvider } from "@/context/CustomerDetectionContext";
import { CustomerSupportContextProvider } from "@/context/CustomerSupportContext";
import { DataDogContextProvider } from "@/context/DataDogContext";
import { DeviceInformationContextProvider } from "@/context/device-information/DeviceInformationContext";
import { ErrorBoundaryContextProvider } from "@/context/ErrorBoundaryContext";
import { FeatureToggleContextProvider } from "@/context/FeaturesToggleContext";
import { IGlobalContextProps } from "@/interfaces/context/global-context/IGlobalContextProps";
import { KeyboardContextProvider } from "@/context/KeyboardContext";
import { KioskUserSessionContextProvider } from "@/context/KioskUserSessionContext";
import { LoaderContextProvider } from "./LoaderContext";
import { OptimizelyContextProvider } from "@/context/OptimizelyContext";
import { PaymentInformationContextProvider } from "@/context/PaymentInformationContext";
import { SchedulesContextProvider } from "@/context/SchedulesContext";
import { StoreDetailsContextProvider } from "@/context/store-details/StoreDetailsContext";
import { SystemOutageContextProvider } from "@/context/SystemOutageContext";
import { TimerContextProvider } from "@/context/TimerContext";
import { TwilioAuthenticationContextProvider } from "@/context/TwilioAuthenticationContext";
import { VideoCallContextProvider } from "@/context/VideoCallContext";

const GlobalContextProvider = ({
  children,
  kioskComputerNames,
}: IGlobalContextProps) => {
  return (
    <LoaderContextProvider>
      <KioskUserSessionContextProvider>
        <DeviceInformationContextProvider kioskComputerNames={kioskComputerNames}>
          <StoreDetailsContextProvider>
            <DataDogContextProvider>
              <CustomerDetectionContextProvider>
                <FeatureToggleContextProvider>
                  <TwilioAuthenticationContextProvider>
                    <TimerContextProvider>
                      <CallContextProvider>
                        <VideoCallContextProvider>
                          <PaymentInformationContextProvider>
                            <BreezeKioskPaymentContextProvider>
                              <KeyboardContextProvider>
                                <SystemOutageContextProvider>
                                  <ErrorBoundaryContextProvider>
                                    <OptimizelyContextProvider>
                                      <SchedulesContextProvider>
                                        <CustomerSupportContextProvider>
                                          {children}
                                        </CustomerSupportContextProvider>
                                      </SchedulesContextProvider>
                                    </OptimizelyContextProvider>
                                  </ErrorBoundaryContextProvider>
                                </SystemOutageContextProvider>
                              </KeyboardContextProvider>
                            </BreezeKioskPaymentContextProvider>
                          </PaymentInformationContextProvider>
                        </VideoCallContextProvider>
                      </CallContextProvider>
                    </TimerContextProvider>
                  </TwilioAuthenticationContextProvider>
                </FeatureToggleContextProvider>
              </CustomerDetectionContextProvider>
            </DataDogContextProvider>
          </StoreDetailsContextProvider>
        </DeviceInformationContextProvider>
      </KioskUserSessionContextProvider>
    </LoaderContextProvider>
  );
};

export default GlobalContextProvider;
