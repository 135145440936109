// Node Modules
import React, {
  createContext,
  useContext,
  useRef,
  useState,
} from "react";
import {
  StopwatchResult,
  useStopwatch
} from "react-timer-hook";

// Scripts
import { callStatuses } from "@/scripts/constant-types/voice/twilioConstants";

// Types
import ICallInformationContext from "@/interfaces/context/video/ICallInformationContext";
import ICallState from "@/interfaces/twilio/video/ICallState";
import IVideoCallContextProvider from "@/interfaces/context/video/IVideoCallContextProvider";
import { VideoProvider } from "@/classes/video-call/VideoProvider";

const callInitialState: ICallState = {
  buttonLabel: "Call Us Now",
  callStatus: callStatuses.inactive,
};

const VideoCallContext: React.Context<ICallInformationContext | undefined> =
  createContext<ICallInformationContext | undefined>(undefined);

const useVideoCallContext = (): ICallInformationContext => {
  const context = useContext(VideoCallContext);
  if (context === undefined) {
    throw new Error("useVideoCallContext must be used within a VideoCallContextProvider");
  }
  return context;
};

const VideoCallContextProvider = ({
  children,
  callStateValue,
  isIncomingCallValue = false,
}: IVideoCallContextProvider): JSX.Element => {
  const [callState, setCallState] = useState<ICallState>(callStateValue ?? callInitialState);
  const chimeAudioElementRef = useRef<HTMLAudioElement | null>(null);
  const [chimeAudioElementVolume, setChimeAudioElementVolume] = useState<number>(1);
  const [isIncomingCall, setIsIncomingCall] = useState<boolean>(isIncomingCallValue);
  const [isUserAllowedToEndCallAlready, setIsUserAllowedToEndCallAlready] = useState<boolean>(false);
  const [playCallChime, setPlayCallChime] = useState<boolean>(false);
  const [showAutoCallModal, setShowAutoCallModal] = useState<boolean>(false);
  const [videoProvider, setVideoProvider] = useState<VideoProvider | null>(null);
  const [wasCallDisconnectedByNscAgent, setWasCallDisconnectedByNscAgent] = useState<boolean>(false);

  const stopWatch: StopwatchResult = useStopwatch({
    autoStart: false,
  });

  const contextValues: ICallInformationContext = {
    chimeAudioElementRef,
    chimeAudioElementVolume,
    callState,
    isIncomingCall,
    isUserAllowedToEndCallAlready,
    playCallChime,
    setCallState,
    setChimeAudioElementVolume,
    setIsIncomingCall,
    setIsUserAllowedToEndCallAlready,
    setPlayCallChime,
    setShowAutoCallModal,
    setVideoProvider,
    setWasCallDisconnectedByNscAgent,
    showAutoCallModal,
    stopWatch,
    videoProvider,
    wasCallDisconnectedByNscAgent,
  };

  return (
    <VideoCallContext.Provider
      value={contextValues}
    >
      {children}
    </VideoCallContext.Provider>
  );
};

export {
  VideoCallContext,
  VideoCallContextProvider,
  useVideoCallContext
};
