// Node modules
import Cookies from "js-cookie";
import cookie from "cookie";

export function getRequestCookies(req) {
  return cookie.parse(req
    ? req.headers.cookie || ""
    : document.cookie);
}

export const setCookieOnBackend = (res: any, name: string, value: any, overwrite: boolean = false) => {
  const overwriteString = overwrite
    ? "overwrite;"
    : "";

  res.setHeader("set-cookie", `${name}=${value}; path=/; ${overwriteString}`);
};

export const getCookieValue = (cookieName:string): string => Cookies.get(cookieName);

export const getCreatedValueFromDatadogSessionCookie = (): number | null => {
  const dataDogSessionCookie = getCookieValue("_dd_s");

  const params = new URLSearchParams(dataDogSessionCookie);
  
  return params.get("created")
    ? parseInt(params.get("created"))
    : null;
};

export const getExpireValueFromDatadogSessionCookie = (): number | null => {
  const dataDogSessionCookie = getCookieValue("_dd_s");
  const params = new URLSearchParams(dataDogSessionCookie);
  
  return params.get("expire")
    ? parseInt(params.get("expire"))
    : null;
};
