// Types
import { IDataDogLogMessages } from "@/interfaces/scripts/constant-types/logging/IDataDogLogMessages";

export const dataDogLogMessages: IDataDogLogMessages = {
  audioCue: {
    audioEnded: "KIOSK_AUDIO_CUE_END",
    audioError: "KIOSK_AUDIO_CUE_ERROR",
    audioPlayed: "KIOSK_AUDIO_CUE_START",
  },
  customerDetection: {
    camera: {
      customerDetected: "KIOSK_CAMERA_CUSTOMER_DETECTED",
      customerDisengaged: "KIOSK_CAMERA_CUSTOMER_DISENGAGED",
      customerEngaged: "KIOSK_CAMERA_CUSTOMER_ENGAGED",
    },
    customerInteracting: "KIOSK_CUSTOMER_INTERACTING",
    proximitySensor: {
      customerDetected: "KIOSK_PROX_SENSOR_CUSTOMER_DETECTED",
      customerDisengaged: "KIOSK_PROX_SENSOR_CUSTOMER_DISENGAGED",
      customerEngaged: "KIOSK_PROX_SENSOR_CUSTOMER_ENGAGED",
    },
    customerScreenTap: "KIOSK_CUSTOMER_SCREEN_TAP",
  },
  payment: {
    breezeRemotePayment: {
      apiGetPaymentInfoRequest: "KIOSK_PAYMENT_PP_API_GET_PAYMENT_INFORMATION",
      apiGetPaymentInfoResponse: "KIOSK_PAYMENT_PP_API_GET_PAYMENT_INFORMATION_RESPONSE",
      breezeRequest: "KIOSK_PAYMENT_PP_BREEZE_REQUEST",
      breezeResponse: "KIOSK_PAYMENT_PP_BREEZE_RESPONSE",
      generalError: "KIOSK_PAYMENT_PP_ERROR",
      paymentProcessingTime: "BREEZE_PUSH_PAYMENT_SLOW_PROCESSING",
      rcmCancelFromCcrOrError: "KIOSK_PAYMENT_PP_RCM_CANCEL(CCR/ERROR)",
      rcmCancelUI: "KIOSK_PAYMENT_PP_RCM_CANCEL(UI)",
      rcmPaymentRequest: "KIOSK_PAYMENT_PP_RCM_PAYMENT_REQUEST",
      rcmPaymentResponse: "KIOSK_PAYMENT_PP_RCM_PAYMENT_RESPONSE",
      rcmStatusResponse: "KIOSK_PAYMENT_PP_RCM_STATUS_RESPONSE",
      retryApiGetPaymentInfo: "KIOSK_PAYMENT_PP_RETRY_API_GET_PAYMENT_INFORMATION",
    },
    oneTimePayment: {
      stepOne: {
        apiGet: "KIOSK_PAYMENT_OTP_STEP_ONE_API_GET_PAYMENT_INFORMATION",
        apiOnlinePaymentsBlocked: "KIOSK_PAYMENT_OTP_STEP_ONE_API_ONLINE_PAYMENTS_BLOCKED",
        continueButtonClicked: "KIOSK_PAYMENT_OTP_STEP_ONE_CONTINUE_BUTTON_CLICKED",
      },
      stepTwo: {
        apiGetKey: "KIOSK_PAYMENT_QR_STEP_ONE_API_GET_PAYMENT_KEY",
        breezeRequest: "KIOSK_PAYMENT_OTP_STEP_TWO_BREEZE_REQUEST",
        breezeResponse: "KIOSK_PAYMENT_OTP_STEP_TWO_BREEZE_RESPONSE",
        generalError: "KIOSK_PAYMENT_OTP_STEP_TWO_ERROR",
        paymentProcessingTime: "ONE_TIME_PAYMENT_SLOW_PROCESSING",
        rcmCancelFromCcrOrError: "KIOSK_PAYMENT_OTP_STEP_TWO_RCM_CANCEL(CCR/ERROR)",
        rcmCancelUI: "KIOSK_PAYMENT_OTP_STEP_TWO_RCM_CANCEL(UI)",
        rcmPaymentRequest: "KIOSK_PAYMENT_OTP_STEP_TWO_RCM_PAYMENT_REQUEST",
        rcmPaymentResponse: "KIOSK_PAYMENT_OTP_STEP_TWO_RCM_PAYMENT_RESPONSE",
        rcmStatusResponse: "KIOSK_PAYMENT_OTP_STEP_TWO_RCM_STATUS_RESPONSE",
        retryApiGetPaymentInfo: "KIOSK_PAYMENT_OTP_STEP_TWO_RETRY_API_GET_PAYMENT_INFORMATION",
      },
    },
  },
};

