// Enums
import { Brand } from "@/enums/Brand";

// Services
import
API,
{ isNetworkError }
  from "@/functions/axiosExternalInstance";
import {
  StatusType,
  logOnDataDog
} from "@/services/dataDogLoggingService";

// Types
import IDeviceInformation from "@/interfaces/device/IDeviceInformation";

// Utils
import { getApiRoutesUrl } from "@/scripts/kioskUtil";

import KcfSystem from "@/classes/hardware-integration-module/KcfSystem";
import { deviceInformationStorageKey } from "@/scripts/constant-types/localStorageKeys";

const urls = {
  getDeviceInformation: `${getApiRoutesUrl()}device-information/get/`,
};

const getBrand = (brands: string[]) => {
  return brands.includes(Brand.ESS)
    ? Brand.ESS
    : brands[0];
};

const getKioskDeviceInformation = async (kioskComputerName: string) => {
  const url = `${urls.getDeviceInformation}?kioskComputerName=${kioskComputerName}`;
  const deviceInformation: IDeviceInformation = {
    isApiCallSuccessful: false,
  };

  const response = await API.get(url)
    .catch(error => {
      logOnDataDog(error, StatusType.error);
      if (isNetworkError(error)) {
        return;
      }
    });

  if (!!response && response.data.data) {
    const brand = getBrand(response.data.data.brands);

    Object.assign(deviceInformation, {
      ...response.data.data,
      brands: undefined,
      brand,
      isApiCallSuccessful: true,
    });
  }

  return deviceInformation;
};

const getDeviceInformationFromKfcSystem = async () => {
  let kioskDeviceInformation: IDeviceInformation;
  try {
    const kcfSystem = new KcfSystem();
    const kioskStatus = await kcfSystem.status();
    if (kioskStatus?.IsSuccess) {
      const kioskComputerName = kioskStatus?.Status?.StatusData?.NetworkName;
      kioskDeviceInformation = await getKioskDeviceInformation(kioskComputerName);
      if (!kioskDeviceInformation.isApiCallSuccessful) {
        logOnDataDog(`Error setting/updating the ${deviceInformationStorageKey} object`, StatusType.error);
      }
    } else {
      logOnDataDog(`Error getting Device Information from KcfSystem`, StatusType.error);
    }
  } catch (error: any) {
    logOnDataDog(error, StatusType.error);
  }
  return kioskDeviceInformation;
}

export {
  getDeviceInformationFromKfcSystem,
  getKioskDeviceInformation
};
