// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars */
// Components
import SimpleKeyboard from "react-simple-keyboard";

// Enums
import { KeyboardType } from "@/enums/KeyboardType";

// Node Modules
import { MutableRefObject } from "react";

export default class OnScreenKeyboard {
  inputValue?: string;
  invokerRef?: MutableRefObject<any>;
  layoutName?: KeyboardType;
  onChange?: (input: string) => void;
  keyboardRef?: MutableRefObject<typeof SimpleKeyboard>;
  showKeyboard?: boolean;

  constructor(
    inputValue?: string,
    invokerRef?: MutableRefObject<any>,
    layoutName?: KeyboardType,
    onChange?: (input: string) => void,
    keyboardRef: MutableRefObject<typeof SimpleKeyboard> = null,
    showKeyboard: boolean = false
  ) {
    this.inputValue = inputValue;
    this.invokerRef = invokerRef;
    this.layoutName = layoutName;
    this.keyboardRef = keyboardRef;
    this.onChange = onChange;
    this.showKeyboard = showKeyboard;
  }
}
