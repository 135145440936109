// Constants
import { callStatuses } from "@/scripts/constant-types/voice/twilioConstants";

// Contexts
import { useCallContext } from "@/context/CallContext";
import { useSchedulesContext } from "@/context/SchedulesContext";
import { useVideoCallContext } from "@/context/VideoCallContext";

// Enums
import { CustomerSupportFeature } from "@/enums/optimizely/CustomerSupportFeature";

// Node Modules
import { useDecision } from "@optimizely/react-sdk";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

// Types
import ICustomerSupportContext from "@/interfaces/context/customer-support/ICustomerSupportContext";
import ICustomerSupportContextProvider from "@/interfaces/context/customer-support/ICustomerSupportContextProvider";
import {
  resetHasAttractionLoopJustBeenClosedIfCallIsActive,
  validateIfInteractionCallFeatureIsEnabled,
  validateIfSupportModalFeatureIsEnabled,
} from "@/scripts/customerSupportHelper";

const CustomerSupportContext = createContext<ICustomerSupportContext | undefined>(undefined);

const useCustomerSupportContext = () => {
  const context = useContext(CustomerSupportContext);
  if (context === undefined) {
    throw new Error("useCustomerSupportContext must be used within a ICustomerSupportContextProvider");
  }
  return context;
};

const CustomerSupportContextProvider = ({
  children,
}: ICustomerSupportContextProvider) => {
  const [customerSupportFeature, setCustomerSupportFeature] = useState<string>("");
  const startCallFunctionRef = useRef<(() => Promise<void>) | undefined>(undefined);
  const [isSupportModalFeatureEnabled, setIsSupportModalFeatureEnabled] = useState<boolean>(false);
  const [hasAttractionLoopJustBeenClosed, setHasAttractionLoopJustBeenClosed] = useState<boolean>(false);
  const [hasInteractionCallBeenTriggeredForThisSession, setHasInteractionCallBeenTriggeredForThisSession] = useState<boolean>(false);
  const [isInteractionCallFeatureEnabled, setIsInteractionCallFeatureEnabled] = useState<boolean>(false);
  const resetHasAttractionLoopJustBeenClosed = (): void => setHasAttractionLoopJustBeenClosed(false);

  const [decision] = useDecision(CustomerSupportFeature.Variation, {
    autoUpdate: true,
  });

  const {
    callState,
  } = useCallContext();

  const {
    isIncomingCall,
    videoProvider,
  } = useVideoCallContext();

  const {
    isNscCurrentlyOpen,
    isStaffingCurrentlyOpen,
  } = useSchedulesContext();

  useEffect(() => {
    const isAudioCallStateActive: boolean = !!callState?.callStatus && callState.callStatus !== callStatuses.inactive;
    const isVideoCallStateActive: boolean = isIncomingCall || !!videoProvider?.isCallActive;

    let customerSupportFeatureFromOptimizely: string = "";
    if (decision && decision.enabled && decision.variationKey) {
      customerSupportFeatureFromOptimizely = decision.variationKey;
    }

    const isInteractionCallEnabled = validateIfInteractionCallFeatureIsEnabled(
      customerSupportFeatureFromOptimizely,
      hasInteractionCallBeenTriggeredForThisSession,
      isAudioCallStateActive,
      isNscCurrentlyOpen,
      isVideoCallStateActive,
      !isStaffingCurrentlyOpen);

    setIsInteractionCallFeatureEnabled(isInteractionCallEnabled);

    const isSupportModalEnabled = validateIfSupportModalFeatureIsEnabled(
      hasAttractionLoopJustBeenClosed,
      isAudioCallStateActive,
      isNscCurrentlyOpen,
      customerSupportFeatureFromOptimizely,
      isVideoCallStateActive);

    setIsSupportModalFeatureEnabled(isSupportModalEnabled);
    setCustomerSupportFeature(customerSupportFeatureFromOptimizely);
    resetHasAttractionLoopJustBeenClosedIfCallIsActive(
      isAudioCallStateActive,
      isVideoCallStateActive,
      resetHasAttractionLoopJustBeenClosed);
  }, [
    callState,
    decision,
    hasAttractionLoopJustBeenClosed,
    hasInteractionCallBeenTriggeredForThisSession,
    isIncomingCall,
    isNscCurrentlyOpen,
    isStaffingCurrentlyOpen,
    videoProvider?.isCallActive,
  ]);

  const contextValues: ICustomerSupportContext = {
    customerSupportFeature,
    isInteractionCallFeatureEnabled,
    resetHasAttractionLoopJustBeenClosed,
    setHasAttractionLoopJustBeenClosed,
    setHasInteractionCallBeenTriggeredForThisSession,
    setIsSupportModalFeatureEnabled,
    isSupportModalFeatureEnabled,
    startCallFunctionRef,
  };

  return (
    <CustomerSupportContext.Provider
      value={contextValues}
    >
      {children}
    </CustomerSupportContext.Provider>
  );
};

export {
  CustomerSupportContext,
  CustomerSupportContextProvider,
  useCustomerSupportContext
};
