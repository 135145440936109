import Authorization from "@/src/models/classes/payment/Authorization";
import Form from "@/src/models/classes/payment/Form";
import IPaymentInformation from "@/interfaces/payment/IPaymentInformation";
import PaymentSummary from "@/src/models/classes/payment/PaymentSummary";

export default class OneTimePaymentInformation implements IPaymentInformation {
  accountNumber: number;
  authorization: Authorization;
  dimensions: string;
  formInformation: Form;
  isPastDue: boolean;
  key: string;
  nextPaidThruDate?: string;
  onlinePaymentsBlocked: boolean;
  ozStoreId: number;
  paidThruDate?: Date;
  paymentSummary: PaymentSummary
  promotionLabel: string;
  rentalId: number;
  totalAmountDue: number;
  unitNumber: string;

  constructor(
    accountNumber: number = 0,
    authorization: Authorization = new Authorization(),
    dimensions: string = "",
    formInformation: Form = new Form(),
    isPastDue: boolean = false,
    key: string = "",
    nextPaidThruDate?: string,
    onlinePaymentsBlocked: boolean = false,
    ozStoreId: number = 0,
    paidThruDate?: Date,
    paymentSummary: PaymentSummary = new PaymentSummary(),
    promotionLabel: string = "",
    rentalId: number = 0,
    totalAmountDue: number = 0,
    unitNumber: string = ""
  ) {
    this.accountNumber = accountNumber;
    this.authorization = authorization;
    this.dimensions = dimensions;
    this.formInformation = formInformation;
    this.isPastDue = isPastDue;
    this.key = key;
    this.nextPaidThruDate = nextPaidThruDate;
    this.onlinePaymentsBlocked = onlinePaymentsBlocked;
    this.ozStoreId = ozStoreId;
    this.paidThruDate = paidThruDate;
    this.paymentSummary = paymentSummary;
    this.promotionLabel = promotionLabel;
    this.rentalId = rentalId;
    this.totalAmountDue = totalAmountDue;
    this.unitNumber = unitNumber;
  }
}
