// Node modules
import {
  useEffect,
  useRef,
} from "react";

// Types
import IAudioProps from "@/interfaces/global-components/audio/IAudioProps";

const Audio = ({
  audioElementRef,
  audioSrcUrl,
  dataTestId = "audio",
  loop,
  onEnded,
  playAudio,
  setVolume,
  volume = 1,
}: IAudioProps): JSX.Element | null => {
  if (!audioSrcUrl) {
    return null;
  }

  const audioRef = audioElementRef ?? useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume])

  const startAudio = (): void => {
    try {
      audioRef.current!.play();
    } catch (error) {
      console.error(error);
    }
  };
  const stopAudio = (): void => {
    try {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setVolume(1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (playAudio) {
      startAudio();
    } else {
      stopAudio();
    }
  }, [playAudio]);

  return (
    <audio
      data-testid={dataTestId}
      loop={loop}
      ref={audioRef}
      role="audio"
      onEnded={onEnded}
    >
      <source
        role="source"
        src={audioSrcUrl}
      />
    </audio>
  )
}

export default Audio;
