// Services
import API from "@/functions/axiosExternalInstance";
import { getApiRoutesUrl } from "@/scripts/kioskUtil";

// Types
import IStoreDetails from "@/interfaces/splash-screen/IStoreDetails";

/**
 * pass a ozStoreId to get the hasAvailableInventory flag and postalCode
 * @param ozStoreId represents the storeId used for facilities. E.g.: 1000000217
 */
const getStoreDetails = async (ozStoreId: string): Promise<IStoreDetails> => {
  const url: string = `${getApiRoutesUrl()}store-details/get/?ozStoreId=${ozStoreId}`;
  try {
    const {
      address,
      adminFee,
      hasAvailableInventory,
      hoursOfOperation,
    } = (await API.get(url)).data.data.store

    return {
      address,
      adminFee,
      hasAvailableInventory,
      hoursOfOperation,
    };
  } catch (error) {
    console.error(`Could not get data from api for store ${ozStoreId}`);
  }
};

export default {
  getStoreDetails,
};
