// Node Modules
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

// Types
import IBaseContextProvider from "@/interfaces/context/IBaseContextProvider";
import ITwilioAuthenticationContext from "@/interfaces/context/ITwilioAuthenticationContext";

const TwilioAuthenticationContext = createContext<ITwilioAuthenticationContext | undefined>(undefined);

const useTwilioAuthenticationContext = () => {
  const context = useContext(TwilioAuthenticationContext);
  if (context === undefined) {
    throw new Error("useTwilioAuthenticationContext must be used within a TwilioAuthenticationContextProvider");
  }

  return context;
};

const TwilioAuthenticationContextProvider = ({
  children,
}: IBaseContextProvider) => {
  const [token, setToken] = useState<string>(undefined);

  const tokenValue = useMemo(() => ({
    token,
    setToken,
  }), [token, setToken]);

  return (
    <TwilioAuthenticationContext.Provider
      value={tokenValue}
    >
      {children}
    </TwilioAuthenticationContext.Provider>
  );
};

export {
  TwilioAuthenticationContext,
  TwilioAuthenticationContextProvider,
  useTwilioAuthenticationContext,
};
