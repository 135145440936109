// Contexts
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";

// Node Modules
import {
  OptimizelyProvider,
  createInstance,
} from "@optimizely/react-sdk"

// Types
import IOptimizelyContextProvider from "@/interfaces/context/optimizely/IOptimizelyContextProvider";
import { useStoreDetailsContext } from "./store-details/StoreDetailsContext";

const optimizely = createInstance({
  sdkKey: process.env.OPTIMIZELY_SDK_KEY,
  logLevel: "error",
})

const OptimizelyContextProvider = ({
  children,
}: IOptimizelyContextProvider) => {
  const {
    deviceInformation,
  } = useDeviceInformationContext();

  const {
    stateAbbreviation,
  } = useStoreDetailsContext();

  return (
    deviceInformation && (
      <OptimizelyProvider
        optimizely={optimizely}
        user={{
          id: `kiosk-${deviceInformation.kioskComputerName}`,
          attributes: {
            kiosk_brand: deviceInformation.brand ?? "",
            kiosk_kiosk_id: deviceInformation.kioskId ?? "",
            kiosk_state: stateAbbreviation ?? "",
            kiosk_store_number: deviceInformation.storeNumber ?? "",
          },
        }}
      >
        {children}
      </OptimizelyProvider>
    )
  );
};

export { OptimizelyContextProvider };
