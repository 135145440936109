// Context
import { useStoreDetailsContext } from "@/context/store-details/StoreDetailsContext";

// Node Modules
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

// Scripts
import {
  calculateTimeOutForIntervalScheduleChecks,
  getHoursOfOperationByType,
  getNscContentfulDefaultHours,
  setFormattedHoursOfOperationInLocalStorage
} from "@/scripts/hoursOfOperationHelper";

// Types
import { IDefaultStoreScheduleModified } from "@/src/models/contentful/IDefaultStoreScheduleModified";
import { IHoursOfOperationDetails } from "@/interfaces/splash-screen";
import IScheduleContext from "@/interfaces/context/schedules/IScheduleContext";
import IScheduleContextProvider from "@/interfaces/context/schedules/IScheduleContextProvider";
import { hoursOfOperationParsingUtils } from "@/scripts/hourOfOperationParsingUtils"

const SchedulesContext = createContext<IScheduleContext | undefined>(undefined);

const useSchedulesContext = () => {
  const context = useContext(SchedulesContext);
  if (!context) {
    throw new Error("useHoursOfOperationContext must be used within a NSCHoursOfOperationContextProvider");
  }

  return context;
};

const SchedulesContextProvider = ({
  children,
}: IScheduleContextProvider) => {
  const [gateSchedule, setGateSchedule] = useState<IHoursOfOperationDetails>();
  const [staffingSchedule, setStaffingSchedule] = useState<IHoursOfOperationDetails>();
  const [officeSchedule, setOfficeSchedule] = useState<IHoursOfOperationDetails>();
  const [nscSchedule, setNscSchedule] = useState<IHoursOfOperationDetails>();
  const [isGateCurrentlyOpen, setIsGateCurrentlyOpen] = useState<boolean>(false);
  const [isNscCurrentlyOpen, setIsNscCurrentlyOpen] = useState<boolean>(false);
  const [isStaffingCurrentlyOpen, setIsStaffingCurrentlyOpen] = useState<boolean>(false);
  const [isOfficeCurrentlyOpen, setIsOfficeCurrentlyOpen] = useState<boolean>(false);
  const [contentfulTwentyFourHoursHeading, setContentfulTwentyFourHoursHeading] = useState<string>();
  const [contentfulNscHours, setContentfulNscHours] = useState<IDefaultStoreScheduleModified>();
  const schedulesCheckInterval = useRef<NodeJS.Timeout | null>(null);
  const schedulesCheckTimeout = useRef<NodeJS.Timeout | null>(null);

  const {
    hoursOfOperation,
  } = useStoreDetailsContext();

  const setSchedules = (schedules: IHoursOfOperationDetails[]) => {
    setGateSchedule(getHoursOfOperationByType(schedules, "gate"));
    setOfficeSchedule(getHoursOfOperationByType(schedules, "office"));
    setStaffingSchedule(getHoursOfOperationByType(schedules, "staffing"));
  }

  const setContentfulValuesOnScheduleContext = (nSCHoursFromContentful: IDefaultStoreScheduleModified, twentyFourHoursHeading: string) => {
    setContentfulTwentyFourHoursHeading(twentyFourHoursHeading);
    setContentfulNscHours(nSCHoursFromContentful);

    const nscHoursOfOperation = getNscContentfulDefaultHours(nSCHoursFromContentful);
    setNscSchedule(nscHoursOfOperation);
  }

  useEffect(() => {
    if (hoursOfOperation && hoursOfOperation.length > 0) {
      setSchedules(hoursOfOperation);
    }
  }, [hoursOfOperation]);

  useEffect(() => {
    const allSchedulesAreSet: boolean = !!nscSchedule && !!gateSchedule && !!officeSchedule && !!staffingSchedule;
    const intervalHasBeenSet: boolean = !!schedulesCheckTimeout.current && !!schedulesCheckInterval.current;

    if (allSchedulesAreSet && !intervalHasBeenSet) {
      checkStatusOfAllSchedules();
      setSchedulesChecksOnIntervalTime();
      setSchedulesInLocalStorage();
    }
  }, [
    gateSchedule,
    nscSchedule,
    officeSchedule,
    staffingSchedule,
  ]);

  const checkStatusOfAllSchedules = () => {
    const isGateClosed = hoursOfOperationParsingUtils.isScheduleInCloseHours(gateSchedule!);
    setIsGateCurrentlyOpen(!isGateClosed);

    const isNscClosed = hoursOfOperationParsingUtils.isScheduleInCloseHours(nscSchedule!);
    setIsNscCurrentlyOpen(!isNscClosed);

    const isStaffingClosed = hoursOfOperationParsingUtils.isScheduleInCloseHours(staffingSchedule!);
    setIsStaffingCurrentlyOpen(!isStaffingClosed);

    const isOfficeClosed = hoursOfOperationParsingUtils.isScheduleInCloseHours(officeSchedule!);
    setIsOfficeCurrentlyOpen(!isOfficeClosed);
  }

  const setSchedulesChecksOnIntervalTime = () => {
    const everyXMinutesOfAnHour = 30;

    const intervalScheduleChecks = calculateTimeOutForIntervalScheduleChecks(everyXMinutesOfAnHour);

    schedulesCheckTimeout.current = setTimeout(() => {
      checkStatusOfAllSchedules();
      schedulesCheckInterval.current = setInterval(checkStatusOfAllSchedules, intervalScheduleChecks.intervalTimeInMilliseconds);
    }, intervalScheduleChecks.timeOutForIntervalStart);
  };

  const setSchedulesInLocalStorage = () => {
    setFormattedHoursOfOperationInLocalStorage(
      [
        gateSchedule!,
        staffingSchedule!,
        officeSchedule!,
        nscSchedule!,
      ],
      contentfulNscHours!,
      contentfulTwentyFourHoursHeading);
  }

  useEffect(() => {
    return () => {
      clearInterval(schedulesCheckInterval.current!);
      clearTimeout(schedulesCheckTimeout.current!);
    }
  }, []);

  const contextValues: IScheduleContext = {
    gateSchedule: gateSchedule!,
    isGateCurrentlyOpen,
    isStaffingCurrentlyOpen,
    isOfficeCurrentlyOpen,
    isNscCurrentlyOpen,
    nscSchedule: nscSchedule!,
    officeSchedule: officeSchedule!,
    setContentfulValuesOnScheduleContext,
    staffingSchedule: staffingSchedule!,
  };

  return (
    <SchedulesContext.Provider
      value={contextValues}
    >
      {children}
    </SchedulesContext.Provider>
  );
};

export {
  SchedulesContext,
  SchedulesContextProvider,
  useSchedulesContext,
};
