// Services
import { postDataDogMetrics } from "@/components/services/dataDogMetricsService";

// Types
import IDeviceInformation from "@/interfaces/device/IDeviceInformation";

export const getIsSoftwareVisible = (deviceInformation: IDeviceInformation):boolean => {
  if (document.visibilityState == "hidden" || !document.hasFocus()) {
    const host = `https://${window.location.host}`;
    postDataDogMetrics(deviceInformation.kioskId, deviceInformation.kioskComputerName, deviceInformation.storeNumber, host, process.env.DATADOG_SOFTWARE_VISIBILITY_METRIC_TAG)
    return false;
  }

  return true;
}
