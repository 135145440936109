// Components
import CenteredModal from "@/gc/modals/CenteredModal";
import Spinner from "@/gc/animations/spinner/Spinner";

// Styles
import getAutocallModalStyles from "./StylesAutocallModal";

// Types
import IAutocallModalProps from "@/interfaces/global-components/modals/IAutocallModalProps";

const AutocallModal = ({
  buttonText,
  footerText,
  message,
  onClick,
  title,
}: IAutocallModalProps) => {
  const {
    className,
    styles,
  } = getAutocallModalStyles();

  return (
    <>
      <div
        className={`autocall-modal-container ${className}`}
        role="autocall-modal"
      >
        <CenteredModal
          isModalClosable
          buttonOnClick={onClick}
          buttonText={buttonText}
          className={className}
          height="70rem"
          message={message}
          title={title}
          onClose={onClick}
        >
          <>
            <Spinner />
            <div>
              <p
                className={`footer-text ${className}`}
                role="auto-call-modal-footer"
              >{footerText}</p>
            </div>
          </>
        </CenteredModal>
      </div>
      {styles}
    </>
  );
};

export default AutocallModal;
