export const callEvents = {
  accept: "accept",
  disconnect: "disconnect",
  error: "error",
  reconnecting: "reconnecting",
  ringing: "ringing",
};

export const callStatuses = {
  active: "active",
  connecting: "connecting",
  inactive: "inactive",
};

export const deviceEvents = {
  error: "error",
  tokenWillExpire: "tokenWillExpire",
};
