// Components
import Audio from "@/gc/audio/Audio";
import AutocallModal from "@/gc/modals/autocall-modal/AutocallModal";

// Context
import { useFeatureToggleContext } from "@/context/FeaturesToggleContext";
import { useVideoCallContext } from "@/context/VideoCallContext";

// Node Modules
import {
  useEffect,
  useState
} from "react";

// Services
import environmentVariablesService from "@/components/services/environmentVariablesService";

// Types
import { IAutoCallModalModified } from "@/src/models/contentful/IAutoCallModalModified";
import { ICustomMediaModified } from "@/src/models/contentful/ICustomMediaModified";
import IGlobalComponentsHandlerProps from "@/interfaces/global-components/IGlobalComponentsHandlerProps";

const GlobalComponentsHandler = ({
  globalLayoutFields,
}: IGlobalComponentsHandlerProps): JSX.Element => {
  const [isClient, setIsClient] = useState<boolean>(false);
  const autoCallModalContent: IAutoCallModalModified = globalLayoutFields.autoCallModal;
  const outboundCallRingtone: ICustomMediaModified | undefined = globalLayoutFields.callRingtones.find(item => item.fields.key === "KIOSK_OUTBOUND_RINGTONE" && item.fields.enabled);
  const {
    chimeAudioElementRef,
    chimeAudioElementVolume,
    playCallChime,
    setChimeAudioElementVolume,
    setShowAutoCallModal,
    showAutoCallModal,
  } = useVideoCallContext();

  const {
    isTwilioFlexEnabled,
  } = useFeatureToggleContext();

  const reduceCallChimeVolumeLevel = (): void => {
    const volume: number = environmentVariablesService.getKioskCallChimeReducedVolumeLevel();
    setChimeAudioElementVolume(volume);
  };

  const closeAutoCallModal = (): void => {
    reduceCallChimeVolumeLevel();
    setShowAutoCallModal(false);
  };

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <div>
      {
        showAutoCallModal && (
          <AutocallModal
            buttonText={autoCallModalContent.fields.buttonText!}
            footerText={autoCallModalContent.fields.footerText!}
            message={autoCallModalContent.fields.descriptionText!}
            title={autoCallModalContent.fields.title!}
            onClick={() => closeAutoCallModal()}
          />
        )
      }
      {isClient && isTwilioFlexEnabled() && (
        <Audio
          loop
          audioElementRef={chimeAudioElementRef}
          audioSrcUrl={outboundCallRingtone?.fields.media.fields.file.url}
          dataTestId="call-chime"
          playAudio={!!outboundCallRingtone && playCallChime}
          setVolume={setChimeAudioElementVolume}
          volume={chimeAudioElementVolume}
        />
      )}
    </div>
  )
}

export default GlobalComponentsHandler;
