// Node Modules
import Link from "next/link";

// Services
import dataLayerService from "@/services/dataLayerService";

// Themes
import theme from "@/theme";

// Types
import IButtonProps from "@/interfaces/global-components/button/IButtonProps";

export const Button = ({
  backgroundColor,
  children,
  className = "small dark",
  dataLayerEventName,
  disabled,
  fontColor,
  fontFamily,
  fontSize,
  height,
  href,
  isExternalHref,
  id,
  onClick,
  padding,
  radius,
  testId,
  title,
  type = "button",
  width,
}: IButtonProps) => {
  const buttonContent = children || title;
  const buttonProps = {
    className: `${!children
      ? className
      : "large"}`,
    "data-testid": testId,
    id,
    type,
    disabled,
    onClick: onClick
      ? (event) => dataLayerService.onClickWithDataLayerEvent(() => onClick(event), dataLayerEventName)
      : undefined,
  };

  return (
    <>
      {href
        ? (
          <Link
            legacyBehavior
            href={href}
          >
            {
              isExternalHref ?
                  (
                    <a
                      className={`${!children
                        ? className
                        : "large"}`}
                      data-testid={testId}
                      id={id}
                      target="_blank"
                      type={type}
                    >
                      {buttonContent}
                    </a>
                  ) :
                  (
                    <button
                      className={`${!children
                        ? className
                        : "large"}`}
                      data-testid={testId}
                      id={id}
                      type={type}
                      onClick={onClick}
                    >
                      {buttonContent}
                    </button>
                  )
            }
          </Link>
          )
        : (
          <button
            {...buttonProps}
          >
            {buttonContent}
          </button>
          )}
      <style jsx>
        {`
          a {
            text-decoration: none;
          }
          
          button {
            text-transform: ${!children
          ? "uppercase"
          : "none"};
            background:none;
          }
          .disabled{
            background: ${theme.brandColors.disabledGrey}!important;
            border:none!important;
            color:${theme.brandColors.white}!important;
          }
          .dark {
            background: ${theme.brandColors.primaryGreen};
            border: 2px solid ${theme.brandColors.primaryGreen};
            color: ${theme.brandColors.black};
          }

          .darker {
            background: ${theme.brandColors.primaryGreen};
            color: ${theme.brandColors.black};
          }

          .green {
            background: ${theme.brandColors.moderateGreen};
            border: none !important;
          }

          .large {
            align-items: center;
            background-color: white;
            border: 3px solid ${theme.borderColors.lightGray};
            border-radius: ${radius ?? "20px"};
            color: ${theme.brandColors.primaryGray};
            font-family: ${fontFamily ?? theme.fontFamilies.arial};
            font-size: ${fontSize ?? "4rem"};
            font-weight: bold;
            height: ${height ?? "20rem"};
            margin-bottom: 4.8rem;
            width:${width ?? "72.4%"};
          }

          .large:focus {
            background-color: ${theme.brandColors.highlightGray};
          }

          
          .lighter {
            background: white;
            border: 3px solid ${theme.brandColors.white} !important;
            color: ${fontColor ?? theme.brandColors.primaryGray};
          }

          .small {
            background: ${backgroundColor && backgroundColor};
            border: 2px solid ${theme.brandColors.primaryGreen};
            border-radius: ${radius ?? "10rem"};
            display: inline-block;
            font-weight: bold;
            font-size: ${fontSize ?? "3.2rem"};
            height: ${height ?? "11.7rem"};
            padding: ${padding ?? "4%"};
            text-align: center;
            width: ${width ?? "46rem"};
          }
          .light {
            background: white;
            border: 3px solid ${theme.brandColors.primaryGray};
            color: ${theme.brandColors.primaryGray};
          }
      `}
      </style>
    </>
  );
};

export default Button;
