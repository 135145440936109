/**
 * function to replace all ocurrences in a string with a replacementText.
 * @param text The string of text containing the ocurrences to be replaced.
 * @param searchTerm The string of text containing the text to be found and replaced.
 * @param replaceText The string of text to replace the searchTerm.
 * @returns A new string with the ocurrences replaced by the new string.
 */
export const replaceAll = (text: string, searchTerm: string, replaceText: string) => {
  return text.split(searchTerm).join(replaceText);
};

/**
 * Forces a store number has 4 characters
 * @param storeNumber The store number
 * @returns A new string with the ocurrences replaced by the new string.
 */
export const padStartStoreNumber = (storeNumber: number) => {
  return storeNumber.toString().padStart(4, "0");
};
