export default {
  banners: {
    accentGreen: "#F4FAE8",
    black: "#222222",
  },
  borderColors: {
    black: "#222222",
    lightGray: "#BBBBBB",
    silver: "#C4C4C4",
  },
  brandColors: {
    accentGreen: "#F4FAE8",
    backgroundGray: "#E5E5E5",
    black: "#222222",
    breaklineGray: "#CECECE",
    darkGray: "#424242",
    disabledGrey: "#BBBBBB",
    fiftyGray: "#979797",
    highlightGray: "#F3F3F3",
    moderateGreen: "#90C63E",
    primaryGray: "#54565B",
    primaryGreen: "#91C717",
    red: "#B00020",
    silver: "#C4C4C4",
    white: "#ffffff",
  },
  fontColors: {
    black: "#222222",
    primaryGray: "#54565B",
    primaryGreen: "#91C717",
    white: "#ffffff",
  },
  fontFamilies: {
    arial: "arial",
    ramaGothicSemiBold: "RamaGothicSemiBold",
  },
  surfaceColors: {
    black: "#222222",
    error: "#B00020",
    gray1: "#666666",
    gray2: "#BBBBBB",
    gray3: "#CECECE",
    gray4: "#F3F3F3",
    silver: "#C4C4C4",
  },
  textColors: {
    actualBlack: "#000000",
    black: "#222222",
    error: "#B00020",
    gray1: "#666666",
    gray2: "#BBBBBB",
    gray3: "#CECECE",
    gray4: "#F3F3F3",
    primaryGray: "#54565B",
  },
};
