// Types
import HardwareComponent from "@/classes/hardware-integration-module/HardwareComponent";
import IKcfSystemPayload from "@/interfaces/hardware-integration-module/IKcfSystemPayload";

class KcfSystem extends HardwareComponent {
  protected componentName: string = "KcfSystem";
  protected payload: IKcfSystemPayload = {
    Name: this.componentName,
    Type: "KcfSystem",
    Model: "KCF",
    Logger: {
      LogConfig: {
        ShowFile: false,
        ShowLine: false,
        RegexStackTraceFilter: " *at Kiosk\\.Component\\.Framework\\.",
      },
    },
  };
};

export default KcfSystem;
