export default class Authorization {
  authorizationId: number;
  edgeExpressCancelTransactionEndpointUrl: string;
  edgeExpressEndpoint: string;
  edgeExpressStatusTransactionEndpointUrl: string;
  edgeExpressXml: string;
  orderId: string;

  constructor() {
    this.authorizationId = 0;
    this.edgeExpressCancelTransactionEndpointUrl = "";
    this.edgeExpressEndpoint = "";
    this.edgeExpressStatusTransactionEndpointUrl = "";
    this.edgeExpressXml = "";
    this.orderId = "";
  }
}
