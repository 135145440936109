// Context
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";

// Node Modules
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

// Scripts
import { proximitySensorThresholdKey } from "@/scripts/constant-types/localStorageKeys";

// Services
import {
  StatusType,
  logOnDataDog
} from "@/services/dataDogLoggingService";

// Types
import ICustomerDetectionContext from "@/interfaces/context/customer-detection/ICustomerDetectionContext";
import ICustomerDetectionContextProvider from "@/interfaces/context/customer-detection/ICustomerDetectionContextProvider";
import IProximitySensorThreshold from "@/interfaces/context/proximity-sensor/IProximitySensorThreshold";
import {
  getProximitySensorThresholdValues,
  setLocalStorageObject
} from "@/services/localStorageService";

const CustomerDetectionContext = createContext<ICustomerDetectionContext | undefined>(undefined);
const useCustomerDetectionContext = (): ICustomerDetectionContext => {
  const context = useContext(CustomerDetectionContext);
  if (context === undefined) {
    throw new Error("useCustomerDetectionContext must be used within a CustomerDetectionContextProvider");
  }
  return context;
};

const CustomerDetectionContextProvider = ({
  children,
}: ICustomerDetectionContextProvider) => {
  const clearCustomerDetectionEventRef = useRef<((() => void) | undefined)>(undefined);
  const [isCustomerInteractingWithTheScreen, setIsCustomerInteractingWithTheScreen] = useState<boolean>(false);
  const [proximitySensorThreshold, setProximitySensorThreshold] = useState<IProximitySensorThreshold>(getProximitySensorThresholdValues());
  const [shouldPlayAudio, setShouldPlayAudio] = useState<boolean>(false);

  const {
    kioskId,
    storeNumber,
  } = useDeviceInformationContext();

  useEffect(() => {
    setLocalStorageObject(proximitySensorThresholdKey, proximitySensorThreshold);

    if (!!proximitySensorThreshold?.averageCalibrationValue) {
      logOnDataDog(`Proximity sensor calibrated for store: ${storeNumber} kioskId: ${kioskId}`, StatusType.info, {
        configurationValues: {
          ...proximitySensorThreshold,
        },
      });
    }
  }, [proximitySensorThreshold])

  const value: ICustomerDetectionContext = {
    clearCustomerDetectionEventRef,
    isCustomerInteractingWithTheScreen,
    proximitySensorThreshold,
    shouldPlayAudio,
    setIsCustomerInteractingWithTheScreen,
    setProximitySensorThreshold,
    setShouldPlayAudio,
  };

  return (
    <CustomerDetectionContext.Provider
      value={value}
    >
      {children}
    </CustomerDetectionContext.Provider>
  );
};

export {
  CustomerDetectionContext,
  CustomerDetectionContextProvider,
  useCustomerDetectionContext,
};
