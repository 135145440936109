// Logging
import {
  StatusType,
  datadogLogs
} from "@datadog/browser-logs";

// Scripts
import { addMinutesToDate } from "@/scripts/dateHelper";
import {
  cleanOpenEdgeXml,
  openEdgeXmlRegex,
  xhrCleanUp,
  xhrErrorRegex
} from "@/scripts/constant-types/loggingCleanUp";
import {
  getCreatedValueFromDatadogSessionCookie,
  getExpireValueFromDatadogSessionCookie
} from "@/scripts/cookiesHelper";

// Services
import { getGaUserSessionId } from "@/services/browser-storage/sessionStorageService";

// Types
import IApiError from "@/interfaces/api/IApiError";
import IDeviceInformation from "@/interfaces/device/IDeviceInformation";

const addKioskInformationToDatadogLog = (deviceInformation: IDeviceInformation, log: any) => {
  const {
    brand,
    kioskComputerName,
    ozStoreId,
    storeNumber,
  } = deviceInformation;

  const gaUserSessionId: string = getGaUserSessionId();

  log.brand = brand;
  log.ga_user_session_id = gaUserSessionId;
  log.kiosk_computer_name = kioskComputerName;
  log.store = ozStoreId;
  log.store_number = storeNumber;

  return log;
}

const cleanObject = (objectToClean: any, propertyNameToClean: string, regex: RegExp, replaceValue: string) => {
  const propertyValueToClean = objectToClean[propertyNameToClean];
  const cleanedPropertyValue = cleanValue(propertyValueToClean, regex, replaceValue);

  return {
    ...objectToClean,
    [propertyNameToClean]: cleanedPropertyValue,
  };
};

const cleanValue = (valuesToClean: string, regex: RegExp, replaceValue: string): string => {
  return valuesToClean.replace(regex, replaceValue);
};

const createDataDogLogForPaymentApiResponse = (logMessage: string, data: any, errors: IApiError[] | null): void => {
  const logType: StatusType = !!data
    ? StatusType.info
    : StatusType.error;

  const error = errors
    ? errors[0]
    : null;

  logOnDataDog(logMessage, logType, {
    paymentInformation: data,
    error,
  });
};

const isItTimeToRefreshDatadogSession = (): boolean => {
  const isDatadogSessionSet = !!window.DD_LOGS.getInternalContext()?.session_id;
  const createdValue: number | null = getCreatedValueFromDatadogSessionCookie();
  const expireValue: number | null = getExpireValueFromDatadogSessionCookie();
  const createdDate: Date = new Date(createdValue);
  const expireDate: Date = new Date(expireValue);
  const maxExpirationDate: Date = addMinutesToDate(createdDate, 240);
 
  const isItTimeToRefreshDatadogSession: boolean =
    !isDatadogSessionSet ||
    !createdValue ||
    expireDate <= new Date() ||
    maxExpirationDate <= new Date();

  return isItTimeToRefreshDatadogSession;
}

const logOnDataDog = (errorMessage: string, status: StatusType, object?: any): void => {
  datadogLogs.logger.log(errorMessage, object, status);
};

const removeSensitiveInformationFromPaymentLogs = (log: any): void => {
  if (log.http?.url) {
    log.http = cleanObject(log.http, "url", xhrErrorRegex, xhrCleanUp)
  }

  if (log.paymentInformation?.authorization) {
    log.paymentInformation.authorization = cleanObject(log.paymentInformation.authorization, "edgeExpressXml", openEdgeXmlRegex, cleanOpenEdgeXml);
  }
}

export {
  addKioskInformationToDatadogLog,
  cleanValue,
  createDataDogLogForPaymentApiResponse,
  isItTimeToRefreshDatadogSession,
  logOnDataDog,
  removeSensitiveInformationFromPaymentLogs,
  StatusType
};
