// Services
import { getApiRoutesUrl } from "@/scripts/kioskUtil";
import API, { isNetworkError } from "@/functions/axiosExternalInstance";
import {
  StatusType,
  logOnDataDog
} from "@/services/dataDogLoggingService";

/**
 * pass payload
 * @param payload represents the metrics information object
 */
export const postDataDogMetrics = async (id: string, computerName: string, store: string, host: string, postMetrics: string = process.env.DATADOG_HEARTBEAT_METRIC_NAME) => {
  try {
    const payload = {
      series: [
        {
          metric: postMetrics,
          type: 1, // count
          points: [
            {
              timestamp: Math.round(Date.now() / 1000),
              value: 1,
            },
          ],
          resources: [
            {
              name: `${host}`,
              type: "host",
            },
          ],
          tags: [
            `env:${process.env.NEXT_PUBLIC_VERCEL_ENV}`,
            `device-id:${id}`,
            `device:${computerName}`,
            `store-number:${store}`,
          ],
        },
      ],
    };

    const response = (await API.post(
      `${getApiRoutesUrl()}datadog-metrics/post/`,
      payload
    )).data;

    return response;
  } catch (error) {
    logOnDataDog(error, StatusType.error);
    if (isNetworkError(error)) {
      return;
    }
  }
};
