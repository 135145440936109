// Components
import Camera from "@/gc/camera/Camera";

// Node Modules
import React, {
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";

// Scripts
import { shouldSaveImagesInStorage } from "@/scripts/customerDetectionHelper";

// Services
import {
  StatusType,
  logOnDataDog
} from "@/services/dataDogLoggingService";

// Types
import BlazeFaceDetector from "@/classes/facial-recognition/BlazeFaceDetector";
import ICameraHandlerProps from "@/interfaces/customer-detection/camera/ICameraHandlerProps";
import ICameraReading from "@/interfaces/customer-detection/camera/ICameraReading";
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";

const CameraCustomerDetectionHandler = ({
  mediaStream,
  mediaStreamConstraints,
  setCameraReadings,
  setIsCameraAccessibleFromThisBrowser,
  setMediaStream,
}: ICameraHandlerProps) => {
  const canvasElementRef: MutableRefObject<HTMLCanvasElement | null> = useRef<HTMLCanvasElement | null>(null);
  const videoElementRef: MutableRefObject<HTMLVideoElement | null> = useRef<HTMLVideoElement | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  let faceDetectionInterval: NodeJS.Timeout;
  let faceDetector: BlazeFaceDetector;

  const {
    storeNumber,
  } = useDeviceInformationContext();
        
  useEffect(() => {
    if (mediaStream && videoElementRef.current && !faceDetector) {
      const startFaceDetection = async (): Promise<void> => {
        if (isLoaded) {
          return;
        }

        const shouldSaveCanvasFrames = shouldSaveImagesInStorage(storeNumber);
        faceDetector = new BlazeFaceDetector(videoElementRef.current!, canvasElementRef.current!, shouldSaveCanvasFrames);
        await faceDetector.setUpDetector();
        try {
          faceDetectionInterval = setInterval(async () => {
            const cameraReading: ICameraReading = await faceDetector.runDetector();
            setCameraReadings(cameraReadings => [...cameraReadings, cameraReading]);
            setIsLoaded(true);
          }, parseInt(process.env.CUSTOMER_CAMERA_DETECTION_SET_INTERVAL_MS));
        } catch (error) {
          faceDetector.disposeDetector();
          logOnDataDog("FACE_DETECTION_FAILED", StatusType.error, {
            error,
          });
        }
      };
      startFaceDetection();
    }
    return () => clearInterval(faceDetectionInterval);
  }, [mediaStream, videoElementRef]);
  return (
    <div data-testid="camera-handler">
      <Camera
        canvasElementRef={canvasElementRef}
        mediaStream={mediaStream}
        mediaStreamConstraints={mediaStreamConstraints}
        setIsCameraAccessibleFromThisBrowser={setIsCameraAccessibleFromThisBrowser}
        setMediaStream={setMediaStream}
        videoElementRef={videoElementRef}
      />
    </div>
  )
}

export default CameraCustomerDetectionHandler;
