// Types
import HardwareComponent from "@/classes/hardware-integration-module/HardwareComponent";
import IHardwareComponentPayload from "@/interfaces/hardware-integration-module/IHardwareComponentPayload";
import IProximitySensor from "@/interfaces/hardware-integration-module/proximity-sensor/IProximitySensor";
import IReadPayload from "@/interfaces/hardware-integration-module/proximity-sensor/IReadPayload";
import IReadResponse from "@/interfaces/hardware-integration-module/proximity-sensor/IReadResponse";

// Services
import API from "@/functions/axiosExternalInstance";

class ProximitySensor extends HardwareComponent implements IProximitySensor {
  protected componentName: string = "ProximitySensor";
  protected payload: IHardwareComponentPayload = {
    Name: "ProximitySensor",
    Type: "Gpio",
    Model: "Posiflex_SU100",
    ComPort: 10,
  };

  public async read(readPayload: IReadPayload = {}): Promise<IReadResponse> {
    const url = `${this.himServiceUrl}Read/${this.componentName}/`;
    const response = (await API.post<IReadResponse>(url, readPayload));
    return response.data;
  };
};

export default ProximitySensor;
