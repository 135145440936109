// Context
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";

// Node Modules
import {
  useEffect,
  useState,
} from "react";

// Services
import splashScreenService from "@/components/services/splashScreenService";

// Types
import { IUseStoreDetailsHandler } from "@/interfaces/hooks/store-details/IUseStoreDetailsHandler";
import {
  IHoursOfOperationDetails,
  IStoreAddress,
  IStoreDetails
} from "@/interfaces/splash-screen";

const defaultAddress: IStoreAddress = {
  city: "",
  formattedAddress: null,
  line1: "",
  line2: "",
  line3: "",
  line4: "",
  postalCode: "",
  stateAbbreviation: "",
  stateName: "",
}

export const useStoreDetailsHandler = (): IUseStoreDetailsHandler => {
  const [address, setAddress] = useState<IStoreAddress>(defaultAddress);
  const [adminFee, setAdminFee] = useState<number>(0);
  const [contextInitialize, setContextInitialize] = useState<boolean>(false);
  const [hasAvailableInventory, setHasAvailableInventory] = useState<boolean>(true);
  const [hoursOfOperation, setHoursOfOperation] = useState<IHoursOfOperationDetails[]>([]);
  
  const {
    ozStoreId,
  } = useDeviceInformationContext();
  
  const getStoreDetails = async (): Promise<IStoreDetails> => {
    return await splashScreenService.getStoreDetails(ozStoreId);
  }

  const checkForAvailableInventory = async (): Promise<void> => {
    const storeDetailsResult: IStoreDetails = await getStoreDetails();
    setHasAvailableInventory(storeDetailsResult.hasAvailableInventory);
  }

  const initStoreDetailsContext = async (): Promise<void> => {
    const storeDetailsResult: IStoreDetails = await getStoreDetails();
    setHasAvailableInventory(storeDetailsResult.hasAvailableInventory);
    setAddress(storeDetailsResult.address);
    setAdminFee(storeDetailsResult.adminFee);
    setHoursOfOperation([...hoursOfOperation, ...storeDetailsResult.hoursOfOperation]);
    setContextInitialize(true);
  }

  useEffect(() => {
    if (ozStoreId && !contextInitialize) {
      initStoreDetailsContext();
    }
  }, [ozStoreId]);
  
  return {
    address,
    adminFee,
    checkForAvailableInventory,
    hasAvailableInventory,
    hoursOfOperation,
  }
}
