// Context
import { useCustomerDetectionContext } from "@/context/CustomerDetectionContext";
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";

// Node Modules
import {
  useEffect,
  useState
} from "react";

// Services
import hardwareIntegrationModuleService from "@/components/services/hardwareIntegrationModuleService";

// Types
import IProximitySensorHandlerProps from "@/interfaces/hardware-integration-module/proximity-sensor/IProximitySensorHandlerProps";
import ProximitySensor from "@/classes/hardware-integration-module/proximity-sensor/ProximitySensor";
import ProximitySensorHelper from "@/classes/hardware-integration-module/proximity-sensor/ProximitySensorHelper";

const ProximitySensorHandler = ({
  setIsProxSensorOnlineForThisKiosk,
  setProximitySensorReadings,
}: IProximitySensorHandlerProps) => {
  const [isAutoCalibrationRunning, setIsAutoCalibrationRunning] = useState<boolean>(true);
  const proximitySensorHelper = new ProximitySensorHelper();
  const {
    proximitySensorThreshold,
    setProximitySensorThreshold,
  } = useCustomerDetectionContext();

  const {
    deviceInformation,
  } = useDeviceInformationContext();

  useEffect(() => {
    const autoCalibrateProximitySensor = async (): Promise<void> => {
      const isProxSensorEnabledAndOnlineInThisKiosk: boolean = await proximitySensorHelper.isProxSensorEnabledAndOnlineInThisKiosk(deviceInformation);
      if (isProxSensorEnabledAndOnlineInThisKiosk) {
        setIsProxSensorOnlineForThisKiosk(true);
      }

      const datadogLogObject: Object = {
        isProxSensorEnabledAndOnlineInThisKiosk,
        proximitySensorThreshold,
      };

      const isTimeToAutoCalibrateSensor = await proximitySensorHelper.isTimeToAutoCalibrateProximitySensor(proximitySensorThreshold, deviceInformation, datadogLogObject);

      if (isTimeToAutoCalibrateSensor) {
        await proximitySensorHelper.calibrateProximitySensor(setIsAutoCalibrationRunning, setProximitySensorThreshold);
      } else {
        setIsAutoCalibrationRunning(false);
      }
    };

    const loadProximitySensorComponent = async (): Promise<void> => {
      await hardwareIntegrationModuleService.loadHardwareComponents([new ProximitySensor()], deviceInformation);
      await autoCalibrateProximitySensor();
    };

    loadProximitySensorComponent();
  }, []);

  useEffect(() => {
    if (!isAutoCalibrationRunning) {
      const initializeProximitySensorDetection = async (): Promise<void> => {
        const isProxSensorEnabledAndRunning: boolean = await proximitySensorHelper.isProxSensorEnabledAndOnlineInThisKiosk(deviceInformation);
        if (isProxSensorEnabledAndRunning) {
          await proximitySensorHelper.readValueFromSensorContinuously(setProximitySensorReadings);
        }
      };
      initializeProximitySensorDetection();
    }
  }, [isAutoCalibrationRunning]);
  return (
    <div data-testid="proximity-sensor-handler" />
  )
}

export default ProximitySensorHandler;
