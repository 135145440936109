// Types
import IKioskDiagnosticsSubscriptionProps from "@/interfaces/web-socket-notifications/pusher/IKioskDiagnosticsSubscriptionProps";
import { NextRouter } from "next/router";
import PusherChannelSubscription from "@/classes/web-socket-notifications/PusherChannelSubscription";

class KioskDiagnosticsChannelSubscription extends PusherChannelSubscription {
  protected _channelName: string = "kiosk-diagnostics";
  private _eventNames = {
    run: "run",
  };

  private _router: NextRouter;
  constructor(props: IKioskDiagnosticsSubscriptionProps) {
    super(props);
    this._router = props.router;
  }

  private bindRunEvent(): void {
    this._channel.bind(this._eventNames.run, () => this.eventCallback());
  }

  private eventCallback() {
    const now = new Date();
    const currentHour = now.getHours();

    if (currentHour === 3) {
      this._router.push("/diagnostics?runTestsAutomatically=true");
    }
  }

  public registerEvents(): void {
    if (this._pusher) {
      this._channel = this._pusher.subscribe(this._channelName);
      this.bindRunEvent();
    }
  }
}

export default KioskDiagnosticsChannelSubscription;
