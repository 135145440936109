// Node Modules
const contentful = require("contentful");

/**
 * This client is used to get content from the Kiosk's space in Contentful.
 */
const kioskClient = contentful.createClient({
  accessToken: process.env.KIOSK_CONTENTFUL_ACCESS_TOKEN,
  environment: process.env.KIOSK_CONTENTFUL_ENVIRONMENT,
  space: process.env.KIOSK_CONTENTFUL_SPACE_ID,
});

/**
 * This client is used to get content from the web's team space in Contentful.
 */
const webClient = contentful.createClient({
  accessToken: process.env.PHX_CONTENTFUL_ACCESS_TOKEN,
  environment: process.env.PHX_CONTENTFUL_ENVIRONMENT,
  space: process.env.PHX_CONTENTFUL_SPACE_ID,
});

/**
 * get a page's content from the Kiosk environment in Contentful.
 * @param entryId id of the page entry, which can be found on the contentful url after the entries segment.
 */
const getKioskContent = async (entryId, include = 10) => {
  const content = await kioskClient.getEntry(`${entryId}`, {
    include,
  });

  return content.fields;
};

/**
 * get a page's content from the Web environment in Contentful.
 * @param entryId id of the page entry, which can be found on the contentful url after the entries segment.
 */
const getWebContent = async (entryId, include = 10) => {
  const content = await webClient.getEntry(`${entryId}`, {
    include,
  });

  return content.fields;
};

export default {
  getKioskContent,
  getWebContent,
};
