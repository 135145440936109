// Components
import AttractionLoop from "@/components/attraction-loop/AttractionLoop";

// Context
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";
import { useSystemOutageContext } from "@/context/SystemOutageContext";

// Node Modules
import {
  useEffect,
  useState
} from "react";

// Scripts
import { kioskPageContentEntryIds } from "@/scripts/constant-types/pageContent";
import {
  checkSystemOutageBannerStatus,
  getSystemOutageAttractionLoopInformation,
  isSystemOutageAttractionLoopVideoEnabled
} from "@/scripts/systemOutageHelper";

// Services
import contentService from "@/services/contentService";

// Types
import { IAttractionLoopInfoFields } from "@/contentful-types";
import { ISplashScreenFieldsModified } from "@/interfaces/splash-screen";
import ISystemOutageHandlerProps from "@/interfaces/system-outages/ISystemOutageHandlerProps";

const SystemOutageHandler = ({
  isSystemOutage,
  setIsSystemOutage,
}: ISystemOutageHandlerProps) => {
  const [startBannerCheck, setStartBannerCheck] = useState<boolean>(false);
  const splashScreenEntryId: string = kioskPageContentEntryIds.splashScreen;
  let checkOutageTimeOut: NodeJS.Timeout;
  let isSystemOutageLastValue: boolean = false;

  const {
    deviceInformation,
  } = useDeviceInformationContext();

  const {
    attractionLoopVideoInformation,
    isSystemOutageScheduledForTheNext30Minutes,
    setAttractionLoopVideoInformation,
    setShowSystemOutageBanner,
  } = useSystemOutageContext();

  const stopBannerCheck = (interval: NodeJS.Timeout, isSystemOutage: boolean) => {
    clearInterval(interval);
    setStartBannerCheck(false);
    setIsSystemOutage(isSystemOutage);
  }
  const checkSystemOutageConfigurationFromContentful = async (): Promise<void> => {
    const kioskContent: ISplashScreenFieldsModified = await contentService.getKioskContent(splashScreenEntryId);
    const systemOutageAttractionLoop: IAttractionLoopInfoFields | undefined = getSystemOutageAttractionLoopInformation(kioskContent, deviceInformation);

    if (!systemOutageAttractionLoop) {
      setIsSystemOutage(false);
      return;
    }

    setAttractionLoopVideoInformation(systemOutageAttractionLoop);

    if (isSystemOutageScheduledForTheNext30Minutes(systemOutageAttractionLoop)) {
      setStartBannerCheck(true);
    }

    const isSystemOutageCurrentValue: boolean = isSystemOutageAttractionLoopVideoEnabled(systemOutageAttractionLoop);

    if (isSystemOutageLastValue != isSystemOutageCurrentValue) {
      isSystemOutageLastValue = isSystemOutageCurrentValue;

      setIsSystemOutage(isSystemOutageCurrentValue);
    }
  };

  const checkSystemOutageStatus = (): void => {
    checkOutageTimeOut = setTimeout(
      async () => {
        await checkSystemOutageConfigurationFromContentful();
        checkSystemOutageStatus();
      },
      parseInt(process.env.SYSTEM_OUTAGES_SET_INTERVAL_MS));
  };

  useEffect(() => {
    checkSystemOutageConfigurationFromContentful();
    checkSystemOutageStatus();

    return () => {
      clearTimeout(checkOutageTimeOut);
    }
  }, []);

  useEffect(() => {
    if (startBannerCheck) {
      checkSystemOutageBannerStatus(attractionLoopVideoInformation, setShowSystemOutageBanner, stopBannerCheck);
    }
  }, [startBannerCheck]);

  return (
    isSystemOutage
      ? (
        <AttractionLoop
          closeAttractionLoop={undefined}
          videoSrc={attractionLoopVideoInformation?.video.fields.file.url}
        />
        )
      : null
  )
}

export default SystemOutageHandler;
